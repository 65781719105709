const Influencer1 = () => {
  return (
    <svg
      width="314"
      height="891"
      viewBox="0 0 314 891"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M314 445.5C314 691.543 115.214 891 -130 891C-375.214 891 -574 691.543 -574 445.5C-574 199.457 -375.214 0 -130 0C115.214 0 314 199.457 314 445.5ZM-490.248 445.5C-490.248 645.131 -328.959 806.965 -130 806.965C68.9594 806.965 230.248 645.131 230.248 445.5C230.248 245.869 68.9594 84.0352 -130 84.0352C-328.959 84.0352 -490.248 245.869 -490.248 445.5Z"
        fill="#F5F5F5"
      />
    </svg>
  );
};

export default Influencer1;
