import StyledContent from '../UI/StyledContent';
import investorImg from '../../assets/images/investor.webp';
import styled from 'styled-components';
import StyledH2 from '../UI/StyledH2';
import StyledParagraph from '../UI/StyledParagraph';
import InvestorSvg1 from '../svg/InvestorSvg1';
import InvestorSvg2 from '../svg/InvestorSvg2';

const StyledWrapper = styled.div({
  position: 'relative',
  paddingTop: '130px',
  '@media (max-width: 900px)': {
    paddingTop: '49px'
  }
});

const BlockWrapper = styled(StyledContent)({
  '@media (max-width: 900px)': {
    padding: '0px 12px',
    flexWrap: 'wrap-reverse',
    paddingTop: '24px'
  }
});

const BlockContent = styled.div({
  textAlign: 'left',
  flexBasis: '50%',
  '> p': {
    marginTop: '18px',
    color: '#2F2F2F'
  },
  '> h2': {
    textAlign: 'left'
  },
  '@media (max-width: 900px)': {
    flexBasis: '100%',
    '> img': {
      marginTop: '24px',
      width: '100%'
    },
    '> h2': {
      textAlign: 'center'
    }
  }
});

const StyledImg = styled.img({
  maxWidth: '480px'
});

const StyledInvestor1 = styled.div({
  position: 'absolute',
  top: 0,
  zIndex: -1,
  maxWidth: '10vw',
  '> svg': {
    width: '100%'
  }
});

const StyledInvestor2 = styled.div({
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: -1,
  maxWidth: '20vw',
  '> svg': {
    width: '100%'
  }
});

const FirstBlock = () => {
  return (
    <StyledWrapper>
      <StyledInvestor1>
        <InvestorSvg1 />
      </StyledInvestor1>
      <StyledInvestor2>
        <InvestorSvg2 />
      </StyledInvestor2>
      <BlockWrapper>
        <BlockContent>
          <StyledImg src={investorImg} />
        </BlockContent>
        <BlockContent>
          <StyledH2>For investor</StyledH2>
          <StyledParagraph>
            As for the commercial component of the project - we will provide an additional
            opportunity to monetize Influencers audience on the basis of monthly subscription and
            also, there will be available huge RELEVANT layers of audience for advertising inside
            the platform, by transferring Influencer`s audience from other social networks
          </StyledParagraph>
          <StyledParagraph>
            The level of user retention in the application should be high, as habits are something
            daily, something permanent. Our indicators of task completion, unique content from
            Influencer`s, various thematic articles - will allow these indicators to grow. As the
            business model allows us not to spend huge budgets on attracting each user, it is enough
            just to attract influencers who will transfer their audience to our startup
          </StyledParagraph>
        </BlockContent>
      </BlockWrapper>
    </StyledWrapper>
  );
};

export default FirstBlock;
