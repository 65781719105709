import styled from 'styled-components';
import StyledContent from '../UI/StyledContent';
import StyledH2 from '../UI/StyledH2';
import StyledParagraph from '../UI/StyledParagraph';
import influencerImg from '../../assets/images/influencer.webp';
import Influencer1 from '../svg/Influencer1';
import Influencer2 from '../svg/Influencer2';

const StyledWrapper = styled(StyledContent)({
  flexDirection: 'column',
  '> p': {
    color: '#2F2F2F',
    marginTop: '20px',
    maxWidth: '625px',
    textAlign: 'center'
  }
});

const StyledImg = styled.img({
  maxWidth: '480px',
  margin: '25px 0px 70px',
  objectFit: 'contain',
  '@media (max-width: 600px)': {
    width: '80%'
  }
});

const InfluencerCircle1 = styled.div({
  position: 'absolute',
  left: 0,
  top: 0,
  zIndex: -1,
  maxWidth: '20vw',
  '> svg': {
    width: '100%'
  },
  '@media (max-width: 600px)': {
    top: '-70%',
    maxWidth: '30vw'
  }
});

const InfluencerCircle2 = styled.div({
  position: 'absolute',
  right: 0,
  top: 0,
  maxWidth: '20vw',
  zIndex: -1,
  '> svg': {
    width: '100%'
  },
  '@media (max-width: 600px)': {
    top: '-50%'
  }
});

const BlockWrapper = styled.div({
  position: 'relative',
  padding: '0px 12px'
});

const FirstBlock = () => {
  return (
    <BlockWrapper>
      <InfluencerCircle1>
        <Influencer1 />
      </InfluencerCircle1>
      <InfluencerCircle2>
        <Influencer2 />
      </InfluencerCircle2>
      <StyledWrapper>
        <StyledH2 style={{ marginTop: '79px' }}>For influencer</StyledH2>
        <StyledParagraph>
          For Influencers Habs provides an opportunity to conveniently showcase your habits and what
          makes you the kind of person that masses of people follow.
        </StyledParagraph>
        <StyledImg src={influencerImg} />
      </StyledWrapper>
    </BlockWrapper>
  );
};

export default FirstBlock;
