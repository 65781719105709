/* eslint-disable react/no-unescaped-entities */
import styled from 'styled-components';
import StyledContent from '../UI/StyledContent';
import OpportunitiesSvg from '../svg/OpportunitiesSvg';
import StyledH5 from '../UI/StyledH5';
import StyledParagraph from '../UI/StyledParagraph';
import opportunitiesImg from '../../assets/images/opportunities.webp';
import mobileOpportunitiesImg from '../../assets/images/mobileOpportunites.webp';
import Tora from '../svg/Tora';

const StyledOpportunitiesContent = styled.div({
  background: 'linear-gradient(248.33deg, #F8FCFF 35.78%, #FCFFED 98.36%)',
  position: 'relative',
  overflow: 'hidden'
});

const StyledWrapper = styled(StyledContent)({
  zIndex: 2,
  position: 'relative',
  '@media (max-width: 900px)': {
    flexWrap: 'wrap-reverse',
    padding: '30px',
    paddingBottom: '0px'
  }
});

const StyledBlock = styled.div({
  flexBasis: '50%',
  padding: '12px',
  '> img': {
    maxWidth: '100%',
    objectFit: 'contain'
  },
  '> p': {
    color: '#2F2F2F',
    margin: '1ch 0px'
  },
  '@media (max-width: 900px)': {
    flexBasis: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  }
});

const StyledTora = styled.div({
  position: 'absolute',
  bottom: 0,
  left: 0,
  transform: 'translate(-20%, 20%)',
  zIndex: 0,
  '@media (max-width: 900px)': {
    display: 'none'
  }
});

const StyledImg = styled.img({});

const Opportunities = () => {
  const isMobile = window.innerWidth <= 900;

  return (
    <StyledOpportunitiesContent>
      <StyledTora>
        <Tora />
      </StyledTora>
      <StyledWrapper>
        <StyledBlock style={{ paddingBottom: 0 }}>
          <StyledImg src={isMobile ? mobileOpportunitiesImg : opportunitiesImg} />
        </StyledBlock>
        <StyledBlock>
          <OpportunitiesSvg />
          <StyledH5 style={{ marginTop: '12px' }}>Opportunities for bloggers</StyledH5>
          <StyledParagraph>
            First of all, it's a great way to share your unique secrets of success (habits) with
            your fans and people who follow you.
          </StyledParagraph>
          <StyledParagraph>
            Secondly it's a great way to monetize your habits, something that has always existed -
            but now it's an additional opportunity.
          </StyledParagraph>
          <StyledParagraph>
            On our platform Influencer can upload his daily habits, upload photo/video content,
            interact with his audience, attract new audiences and keep statistics.
          </StyledParagraph>
        </StyledBlock>
      </StyledWrapper>
    </StyledOpportunitiesContent>
  );
};

export default Opportunities;
