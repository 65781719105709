const AnalyticSvg = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="60" height="60" rx="20" fill="#2F2F2F" />
      <g clipPath="url(#clip0_410_327)">
        <path
          d="M23.2617 29.9869C23.2617 29.0739 23.4446 28.2027 23.7752 27.4079L16.586 23.2572C15.5487 25.3198 15 27.6111 15 29.9869C15 33.9936 16.5603 37.7604 19.3934 40.5936C20.121 41.3212 20.9106 41.9642 21.7497 42.5191L25.9001 35.3304C24.297 34.0975 23.2617 32.161 23.2617 29.9869Z"
          fill="white"
        />
        <path
          d="M19.3943 19.3803C18.6666 20.108 18.0236 20.8976 17.4688 21.7367L24.6574 25.887C25.7214 24.5036 27.3094 23.5431 29.122 23.306V15.0131C25.4459 15.2242 22.0193 16.7555 19.3943 19.3803Z"
          fill="white"
        />
        <path
          d="M40.6059 19.3803C37.9809 16.7555 34.5543 15.2242 30.8782 15.0131V23.306C34.1799 23.738 36.7375 26.5693 36.7375 29.9869C36.7375 33.7024 33.7147 36.7252 29.9993 36.7252C29.0861 36.7252 28.2151 36.5423 27.4202 36.2118L23.2695 43.401C25.332 44.4383 27.6235 44.9869 29.9993 44.9869C34.0059 44.9869 37.7727 43.4266 40.6059 40.5936C43.439 37.7604 44.9993 33.9936 44.9993 29.9869C44.9993 25.9803 43.439 22.2135 40.6059 19.3803Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_410_327">
          <rect width="30" height="30" fill="white" transform="translate(15 15)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AnalyticSvg;
