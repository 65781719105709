import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import StyledButton from '../UI/StyledButton';
import { useState } from 'react';
import Phone from '../svg/Phone';

const StyledFormWrapper = styled.div({
  padding: '30px',
  borderRadius: '20px',
  border: '2px solid #F5F5F5',
  background: '#FFFFFF',
  minWidth: '550px',
  '@media (max-width: 600px)': {
    minWidth: 'initial',
    width: '100%'
  }
});

const StyledTitle = styled.h5({
  fontFamily: 'Gilroy',
  fontSize: '40px',
  fontWeight: 600,
  lineHeight: '47.72px',
  textAlign: 'left',
  marginTop: '20px'
});

const StyledForm = styled.form({
  display: 'flex',
  flexDirection: 'column',
  '> button': {
    background: 'none',
    outline: 'none',
    border: 'none',
    color: 'white',
    marginTop: '20px',
    maxWidth: '130px',
    '@media (max-width: 600px)': {
      maxWidth: 'initial',
      width: '100%',
      padding: 0
    }
  }
});

const InputWrapper = styled.div({
  marginTop: '20px',
  display: 'flex',
  flexDirection: 'column',
  '> label': {
    fontFamily: 'Gilroy',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '15px',
    letterSpacing: '0.02em',
    marginBottom: '5px'
  }
});

const StyledInput = styled.input({
  fontFamily: 'Gilroy',
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '20px',
  textAlign: 'left',
  padding: '12px 25px',
  border: '1px solid #C3C3C3',
  borderRadius: '20px'
});

const StyledError = styled.span({
  fontFamily: 'Gilroy',
  fontSize: '16px',
  fontWeight: 600,
  textAlign: 'left',
  color: 'red',
  marginTop: '8px'
});

const StyledSend = styled.button({
  cursor: 'pointer',
  '@media (max-width: 600px)': {
    maxWidth: 'initial',
    width: '100%',
    borderRadius: '20px'
  }
});

const StyledSubmit = styled(StyledButton)({
  borderRadius: '20px'
});

const defaultValues = {
  email: '',
  message: ''
};

const Form = () => {
  const [success, setSuccess] = useState(null);

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm({ defaultValues });

  const onSubmit = (values) => {
    fetch('https://formspree.io/f/mqkryglb', {
      method: 'POST',
      body: JSON.stringify({ data: { ...values } }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((res) => {
        setSuccess(res.ok);
      })
      .catch((e) => {
        console.error(new Error(e));
        setSuccess(false);
      });
  };

  return (
    <StyledFormWrapper>
      <Phone />
      <StyledTitle>Contact us</StyledTitle>
      <StyledForm noValidate onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper>
          <label htmlFor="email">Email</label>
          <StyledInput
            type="email"
            placeholder="@gmail.com"
            id="email"
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address'
              }
            })}
          />
        </InputWrapper>

        {errors.email?.message && <StyledError>{errors.email.message}</StyledError>}

        <InputWrapper>
          <label htmlFor="message">Message</label>
          <StyledInput
            type="textarea"
            placeholder="Message"
            id="message"
            {...register('message', { required: 'Message id required' })}
          />
        </InputWrapper>

        {errors.message?.message && <StyledError>{errors.message.message}</StyledError>}
        {success === false && <StyledError>Something went wrong !</StyledError>}
        {success === true && <StyledError style={{ color: 'green' }}>Thanks !</StyledError>}

        <StyledSend type="submit" disabled={success === true}>
          <StyledSubmit>Send</StyledSubmit>
        </StyledSend>
      </StyledForm>
    </StyledFormWrapper>
  );
};

export default Form;
