import styled from 'styled-components';
import StyledContent from '../UI/StyledContent';
import StyledH2 from '../UI/StyledH2';
import member1 from '../../assets/images/member1.webp';
import member2 from '../../assets/images/member2.webp';
import member3 from '../../assets/images/member3.webp';
import member4 from '../../assets/images/member4.webp';

const StyledContentMembers = styled(StyledContent)({
  flexDirection: 'column'
});

const StyledPhotoWrapper = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 283px)',
  marginTop: '46px',
  columnGap: '34px',
  rowGap: '34px',
  '@media (max-width: 1250px)': {
    gridTemplateColumns: 'repeat(2, 283px)'
  },
  '@media (max-width: 650px)': {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(2, 200px)'
  }
});

const StyledImgContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 600px)': {
    maxWidth: '150px'
  }
});

const StyledImg = styled.img({
  height: '283px',
  borderRadius: '50%',
  '@media (max-width: 600px)': {
    height: 'auto',
    objectFit: 'contain'
  }
});

const StyledPosition = styled.h3({
  fontFamily: 'Gilroy',
  fontSize: '30px',
  fontWeight: 600,
  lineHeight: '35.79px',
  textAlign: 'center',
  color: '#2F2F2F',
  marginTop: '7px',
  '@media (max-width: 600px)': {
    fontSize: '16px',
    lineHeight: '18.79px'
  }
});

const TeamMembers = () => {
  return (
    <StyledContentMembers>
      <StyledH2 style={{ marginTop: '132px' }}>Team</StyledH2>
      <StyledPhotoWrapper>
        <StyledImgContainer>
          <StyledImg src={member1} />
          <StyledPosition>Product manager / Business analyst</StyledPosition>
        </StyledImgContainer>
        <StyledImgContainer>
          <StyledImg src={member2} />
          <StyledPosition>UX/UI Designer</StyledPosition>
        </StyledImgContainer>
        <StyledImgContainer>
          <StyledImg src={member3} />
          <StyledPosition>Front end developer</StyledPosition>
        </StyledImgContainer>
        <StyledImgContainer>
          <StyledImg src={member4} />
          <StyledPosition>Backend developer</StyledPosition>
        </StyledImgContainer>
      </StyledPhotoWrapper>
    </StyledContentMembers>
  );
};

export default TeamMembers;
