import styled from 'styled-components';
import StyledContent from '../UI/StyledContent';
import image from '../../assets/images/contactPhone.webp';
import greenCircle from '../../assets/images/greenCircle.svg';
import Form from './Form';

const StyledFormWrapper = styled(StyledContent)({
  marginTop: '112px',
  flexWrap: 'wrap',
  '@media (max-width: 900px)': {
    padding: '10px 12px'
  },
  '@media (max-width: 600px)': {
    flexDirection: 'revert'
  }
});

const StyledContentWrapper = styled.div({
  flexBasis: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '@media (max-width: 900px)': {
    flexBasis: '100%'
  }
});

const StyledImageWrapper = styled(StyledContentWrapper)({
  background: `url(${greenCircle})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center'
});

const StyledImg = styled.img({
  maxWidth: '567px',
  width: '100%'
});

const ContactForm = () => {
  return (
    <StyledFormWrapper id="contacts">
      <StyledImageWrapper>
        <StyledImg src={image} alt="contact phone" />
      </StyledImageWrapper>
      <StyledContentWrapper>
        <Form />
      </StyledContentWrapper>
    </StyledFormWrapper>
  );
};

export default ContactForm;
