const GreenCircle = () => {
  return (
    <svg
      width="888"
      height="888"
      viewBox="0 0 888 888"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M888 444C888 689.214 689.214 888 444 888C198.786 888 0 689.214 0 444C0 198.786 198.786 0 444 0C689.214 0 888 198.786 888 444ZM83.7523 444C83.7523 642.959 245.041 804.248 444 804.248C642.959 804.248 804.248 642.959 804.248 444C804.248 245.041 642.959 83.7523 444 83.7523C245.041 83.7523 83.7523 245.041 83.7523 444Z"
        fill="#F5F5F5"
      />
      <path
        d="M888 444C888 689.214 689.214 888 444 888C198.786 888 0 689.214 0 444C0 198.786 198.786 0 444 0C689.214 0 888 198.786 888 444ZM83.7523 444C83.7523 642.959 245.041 804.248 444 804.248C642.959 804.248 804.248 642.959 804.248 444C804.248 245.041 642.959 83.7523 444 83.7523C245.041 83.7523 83.7523 245.041 83.7523 444Z"
        fill="url(#paint0_linear_352_554)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_352_554"
          x1="444"
          y1="0"
          x2="115.44"
          y2="816.96"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBFF44" />
          <stop offset="1" stopColor="#80FF00" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GreenCircle;
