import styled from 'styled-components';
import Roadmap from '../Roadmap';
import Analytic from './Analytic';
import Opportunities from './Opportunities';
import FirstBlock from './FirstBlock';

const RoadmapWrapper = styled.div({
  marginTop: '76px'
});

const InfluencerPage = () => {
  return (
    <>
      <FirstBlock />
      <Opportunities />
      <Analytic />
      <RoadmapWrapper>
        <Roadmap />
      </RoadmapWrapper>
    </>
  );
};

export default InfluencerPage;
