import styled from 'styled-components';
import StyledContent from '../UI/StyledContent';
import StyledH5 from '../UI/StyledH5';
import StyledParagraph from '../UI/StyledParagraph';
import AnalyticSvg from '../svg/AnalyticSvg';
import analyticImg from '../../assets/images/analytic.webp';

const StyledAnalyticContent = styled.div({
  backgroundColor: '#F7FBFF'
});

const StyledWrapper = styled(StyledContent)({
  padding: '0px 12px',
  '@media (max-width: 900px)': {
    flexWrap: 'wrap'
  }
});

const StyledBlock = styled.div({
  padding: '90px 0px',
  flexBasis: '50%',
  '> img': {
    maxWidth: '100%',
    objectFit: 'contain'
  },
  '> p': {
    color: '#2F2F2F',
    margin: '1ch 0px'
  },
  '@media (max-width: 900px)': {
    padding: '30px',
    flexBasis: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: 0,
    '> img': {
      marginTop: '10px'
    }
  }
});

const StyledImg = styled.img({
  '@media (max-width: 900px)': {
    marginTop: '-80px'
  }
});

const Analytic = () => {
  return (
    <StyledAnalyticContent>
      <StyledWrapper>
        <StyledBlock>
          <AnalyticSvg />
          <StyledH5 style={{ marginTop: '12px', maxWidth: '500px' }}>
            Analytics and Advertisement
          </StyledH5>
          <StyledParagraph>
            We realize the importance of analytics when working with the general public. So we will
            be providing analytical tools for Influencers.
          </StyledParagraph>
          <StyledParagraph>
            Influencer will be able to track the number of subscribers, demographics, and other
            indicators that will allow you to keep proper records of data and monitor audience
            engagement in the content
          </StyledParagraph>
          <StyledParagraph>
            Inside Habs you can customize your ads! Just imagine the number of relevant and filtered
            audience by interest. Target and engage users in Habs using our intuitive ad
            customization tools. Our platform allows you to optimize ad performance directly in the
            app.
          </StyledParagraph>
        </StyledBlock>
        <StyledBlock>
          <StyledImg src={analyticImg} />
        </StyledBlock>
      </StyledWrapper>
    </StyledAnalyticContent>
  );
};

export default Analytic;
