const Tora = () => {
  return (
    <svg
      width="1320"
      height="570"
      viewBox="0 0 1320 570"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M966.301 660.049C966.301 821.25 931.924 967.177 876.355 1072.79C820.78 1178.42 744.047 1243.67 659.359 1243.67C574.67 1243.67 497.938 1178.42 442.363 1072.79C386.794 967.177 352.416 821.25 352.416 660.049C352.416 498.849 386.794 352.922 442.363 247.304C497.938 141.675 574.67 76.4265 659.359 76.4265C744.047 76.4265 820.78 141.675 876.355 247.304C931.924 352.922 966.301 498.849 966.301 660.049Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M660.129 966.423C498.928 966.423 353.001 932.045 247.383 876.476C141.754 820.901 76.5057 744.169 76.5057 659.48C76.5057 574.792 141.754 498.06 247.383 442.484C353.001 386.916 498.928 352.538 660.129 352.538C821.329 352.538 967.256 386.916 1072.87 442.484C1178.5 498.06 1243.75 574.792 1243.75 659.48C1243.75 744.169 1178.5 820.901 1072.87 876.476C967.256 932.045 821.329 966.423 660.129 966.423Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M876.624 443.554C990.61 557.539 1069.49 685.034 1104.88 799.01C1140.27 912.999 1132.15 1013.39 1072.27 1073.28C1012.38 1133.16 911.987 1141.28 797.998 1105.89C684.022 1070.5 556.527 991.622 442.541 877.636C328.555 763.65 249.678 636.155 214.288 522.179C178.895 408.19 187.015 307.795 246.899 247.911C306.783 188.027 407.178 179.907 521.167 215.301C635.143 250.69 762.638 329.568 876.624 443.554Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M876.764 876.688C762.778 990.674 635.283 1069.55 521.307 1104.94C407.318 1140.34 306.923 1132.21 247.039 1072.33C187.155 1012.45 179.035 912.052 214.428 798.063C249.818 684.087 328.696 556.592 442.682 442.606C556.667 328.62 684.162 249.743 798.138 214.353C912.127 178.959 1012.52 187.08 1072.41 246.963C1132.29 306.847 1140.41 407.243 1105.02 521.232C1069.63 635.208 990.75 762.702 876.764 876.688Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M777.324 377.318C926.254 439.007 1047.92 526.612 1124.23 618.369C1200.55 710.136 1231.47 805.997 1199.06 884.239C1166.65 962.481 1077.01 1008.4 958.149 1019.33C839.305 1030.25 691.33 1006.16 542.401 944.474C393.471 882.785 271.808 795.181 195.495 703.424C119.174 611.656 88.2562 515.796 120.665 437.554C153.074 359.311 242.72 313.39 361.576 302.468C480.42 291.547 628.394 315.63 777.324 377.318Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M943.204 777.432C881.516 926.362 793.911 1048.03 702.154 1124.34C610.387 1200.66 514.526 1231.58 436.284 1199.17C358.042 1166.76 312.12 1077.11 301.198 958.256C290.277 839.413 314.36 691.438 376.049 542.509C437.737 393.579 525.342 271.916 617.099 195.603C708.867 119.282 804.727 88.3643 882.969 120.773C961.211 153.182 1007.13 242.828 1018.06 361.684C1028.98 480.528 1004.89 628.502 943.204 777.432Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M542.689 377.103C691.619 315.414 839.593 291.331 958.437 302.252C1077.29 313.174 1166.94 359.096 1199.35 437.338C1231.76 515.58 1200.84 611.441 1124.52 703.208C1048.21 794.965 926.542 882.57 777.612 944.259C628.682 1005.95 480.708 1030.03 361.864 1019.11C243.008 1008.19 153.362 962.266 120.953 884.024C88.5443 805.782 119.462 709.921 195.783 618.154C272.096 526.397 393.759 438.792 542.689 377.103Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M942.909 542.727C1004.6 691.657 1028.68 839.632 1017.76 958.475C1006.84 1077.33 960.916 1166.98 882.674 1199.39C804.432 1231.79 708.571 1200.88 616.804 1124.56C525.047 1048.24 437.442 926.58 375.753 777.651C314.064 628.721 289.981 480.746 300.902 361.903C311.824 243.046 357.746 153.401 435.988 120.992C514.23 88.5827 610.091 119.5 701.858 195.821C793.615 272.134 881.22 393.797 942.909 542.727Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M966.011 646.691C973.043 807.738 945.063 955.026 894.154 1062.97C843.239 1170.92 769.426 1239.45 684.818 1243.15C600.21 1246.84 520.705 1185 460.575 1081.9C400.452 978.804 359.742 834.515 352.711 673.468C345.679 512.421 373.659 365.134 424.568 257.193C475.483 149.24 549.296 80.7063 633.904 77.0123C718.512 73.3182 798.017 135.157 858.147 238.262C918.27 341.355 958.98 485.644 966.011 646.691Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M673.49 966.126C512.443 973.158 365.156 945.178 257.215 894.269C149.262 843.354 80.7282 769.541 77.0342 684.933C73.3401 600.325 135.179 520.82 238.284 460.69C341.377 400.567 485.666 359.857 646.713 352.826C807.76 345.794 955.048 373.774 1062.99 424.683C1170.94 475.598 1239.48 549.411 1243.17 634.019C1246.86 718.627 1185.02 798.132 1081.92 858.262C978.826 918.385 834.537 959.095 673.49 966.126Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M866.97 434.31C985.819 543.216 1070.18 667.149 1110.51 779.472C1150.84 891.809 1147.11 992.463 1089.89 1054.9C1032.68 1117.34 932.734 1129.83 817.31 1099.44C701.899 1069.06 571.085 995.819 452.235 886.914C333.386 778.008 249.022 654.075 208.695 541.751C168.362 429.415 172.096 328.761 229.311 266.322C286.525 203.883 386.471 191.391 501.895 221.779C617.306 252.164 748.12 325.405 866.97 434.31Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M886.009 867.028C777.103 985.877 653.171 1070.24 540.847 1110.57C428.51 1150.9 327.856 1147.17 265.417 1089.95C202.978 1032.74 190.486 932.792 220.874 817.368C251.259 701.957 324.5 571.143 433.405 452.293C542.311 333.444 666.244 249.081 778.568 208.753C890.904 168.421 991.558 172.154 1054 229.369C1116.44 286.584 1128.93 386.529 1098.54 501.953C1068.16 617.365 994.914 748.179 886.009 867.028Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M764.877 372.472C916.356 427.606 1041.72 509.82 1121.97 598.161C1202.22 686.512 1237.29 780.933 1208.32 860.514C1179.36 940.096 1091.8 989.884 973.533 1005.98C855.28 1022.07 706.395 1004.47 554.916 949.335C403.437 894.201 278.069 811.987 197.826 723.646C117.575 635.295 82.5053 540.874 111.47 461.293C140.436 381.712 227.993 331.923 346.26 315.827C464.514 299.733 613.398 317.338 764.877 372.472Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M948.055 764.967C892.921 916.446 810.706 1041.81 722.365 1122.06C634.014 1202.31 539.594 1237.38 460.012 1208.41C380.431 1179.45 330.643 1091.89 314.547 973.623C298.452 855.37 316.058 706.485 371.192 555.006C426.325 403.528 508.54 278.159 596.881 197.916C685.232 117.665 779.653 82.5955 859.234 111.561C938.815 140.526 988.604 228.083 1004.7 346.35C1020.79 464.604 1003.19 613.488 948.055 764.967Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M530.456 382.49C676.553 314.364 823.337 283.849 942.543 289.576C1061.76 295.303 1153.33 337.271 1189.12 414.024C1224.91 490.778 1198.2 587.897 1125.96 682.906C1053.72 777.904 935.992 870.732 789.895 938.859C643.798 1006.98 497.014 1037.5 377.808 1031.77C258.588 1026.05 167.025 984.078 131.234 907.324C95.4427 830.57 122.149 733.452 194.395 638.443C266.633 543.444 384.359 450.616 530.456 382.49Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M937.52 530.499C1005.65 676.597 1036.16 823.38 1030.43 942.587C1024.71 1061.81 982.74 1153.37 905.986 1189.16C829.232 1224.95 732.114 1198.24 637.105 1126C542.106 1053.76 449.278 936.035 381.152 789.938C313.025 643.841 282.511 497.058 288.237 377.851C293.965 258.631 335.932 167.068 412.686 131.277C489.44 95.4859 586.558 122.193 681.567 194.438C776.566 266.676 869.394 384.402 937.52 530.499Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M965.132 633.356C979.181 793.943 957.653 942.311 911.501 1052.37C865.343 1162.44 794.59 1234.13 710.224 1241.51C625.857 1248.89 543.73 1190.58 479.16 1090.19C414.598 989.822 367.633 847.446 353.583 686.859C339.533 526.272 361.062 377.904 407.214 267.845C453.372 157.774 524.125 86.0865 608.491 78.7054C692.858 71.3243 774.985 129.637 839.554 230.021C904.117 330.393 951.082 472.769 965.132 633.356Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M686.827 965.246C526.24 979.295 377.872 957.767 267.813 911.615C157.742 865.457 86.0546 794.704 78.6735 710.338C71.2924 625.971 129.605 543.844 229.989 479.274C330.361 414.712 472.737 367.747 633.324 353.697C793.911 339.647 942.279 361.176 1052.34 407.328C1162.41 453.486 1234.1 524.239 1241.48 608.605C1248.86 692.972 1190.55 775.099 1090.16 839.668C989.79 904.231 847.414 951.196 686.827 965.246Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M856.928 425.497C980.414 529.115 1070.1 649.25 1115.29 759.708C1160.49 870.178 1161.15 970.899 1106.71 1035.77C1052.27 1100.65 952.967 1117.49 836.328 1092.16C719.701 1066.84 585.817 999.378 462.33 895.76C338.843 792.142 249.154 672.007 203.965 561.55C158.772 451.079 158.111 350.358 212.548 285.483C266.984 220.608 366.29 203.768 482.93 229.093C599.557 254.414 733.441 321.879 856.928 425.497Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M894.817 856.973C791.199 980.459 671.064 1070.15 560.606 1115.34C450.136 1160.53 349.415 1161.19 284.54 1106.76C219.664 1052.32 202.825 953.013 228.149 836.373C253.471 719.746 320.936 585.862 424.554 462.375C528.171 338.888 648.306 249.199 758.764 204.011C869.235 158.817 969.956 158.156 1034.83 212.593C1099.71 267.03 1116.55 366.335 1091.22 482.975C1065.9 599.602 998.434 733.486 894.817 856.973Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M752.23 368.174C905.97 416.648 1034.81 493.316 1118.82 578.073C1202.85 662.839 1242.01 755.64 1216.54 836.409C1191.08 917.178 1105.77 970.738 988.321 991.978C870.882 1013.22 721.371 1002.12 567.631 953.647C413.892 905.173 285.056 828.505 201.037 743.748C117.008 658.982 77.8532 566.181 103.32 485.412C128.786 404.643 214.088 351.083 331.54 329.843C448.98 308.606 598.49 319.7 752.23 368.174Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M952.354 752.302C903.881 906.042 827.213 1034.88 742.456 1118.9C657.69 1202.93 564.888 1242.08 484.119 1216.61C403.351 1191.15 349.79 1105.85 328.551 988.393C307.314 870.954 318.408 721.443 366.882 567.703C415.356 413.964 492.024 285.128 576.781 201.109C661.547 117.08 754.348 77.9255 835.117 103.392C915.886 128.858 969.446 214.16 990.686 331.613C1011.92 449.052 1000.83 598.563 952.354 752.302Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M518.472 388.404C661.459 313.97 806.771 277.082 926.114 277.603C1045.47 278.124 1138.78 316.058 1177.88 391.178C1216.99 466.298 1194.54 564.488 1126.51 662.558C1058.48 760.617 944.919 858.492 801.933 932.926C658.946 1007.36 513.633 1044.25 394.29 1043.73C274.934 1043.21 181.627 1005.27 142.522 930.152C103.418 855.033 125.863 756.842 193.895 658.772C261.921 560.713 375.486 462.838 518.472 388.404Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M931.606 518.516C1006.04 661.503 1042.93 806.816 1042.41 926.159C1041.89 1045.51 1003.95 1138.82 928.832 1177.93C853.713 1217.03 755.522 1194.59 657.452 1126.55C559.393 1058.53 461.518 944.963 387.084 801.977C312.65 658.99 275.762 513.678 276.283 394.335C276.805 274.979 314.738 181.672 389.858 142.567C464.978 103.462 563.168 125.907 661.238 193.94C759.297 261.965 857.172 375.53 931.606 518.516Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M963.675 620.071C984.715 779.892 969.679 929.058 928.372 1041.03C887.059 1153.01 819.5 1227.71 735.536 1238.77C651.572 1249.82 566.98 1195.14 498.093 1097.67C429.213 1000.21 376.082 860.02 355.042 700.199C334.001 540.378 349.037 391.212 390.345 279.245C431.657 167.265 499.216 92.5591 583.18 81.5051C667.144 70.451 751.736 125.126 820.623 222.597C889.503 320.058 942.634 460.25 963.675 620.071Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M700.109 963.786C540.288 984.827 391.122 969.791 279.155 928.483C167.175 887.171 92.4693 819.612 81.4152 735.648C70.3611 651.684 125.036 567.091 222.507 498.204C319.968 429.325 460.16 376.194 619.981 355.153C779.803 334.112 928.968 349.149 1040.94 390.456C1152.92 431.768 1227.62 499.327 1238.68 583.291C1249.73 667.256 1195.05 751.848 1097.58 820.735C1000.12 889.614 859.931 942.745 700.109 963.786Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M846.505 417.132C974.394 515.264 1069.24 631.373 1119.2 739.755C1169.17 848.148 1174.22 948.745 1122.67 1015.93C1071.11 1083.12 972.638 1104.28 855.005 1084.06C737.384 1063.85 600.685 1002.29 472.796 904.16C344.907 806.027 250.063 689.918 200.099 581.537C150.129 473.143 145.076 372.547 196.631 305.359C248.186 238.171 346.663 217.016 464.296 237.228C581.917 257.438 718.616 318.999 846.505 417.132Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M903.182 846.542C805.049 974.431 688.941 1069.27 580.559 1119.24C472.165 1169.21 371.569 1174.26 304.381 1122.71C237.193 1071.15 216.038 972.675 236.25 855.041C256.461 737.421 318.022 600.722 416.154 472.833C514.287 344.944 630.395 250.1 738.777 200.136C847.171 150.166 947.767 145.113 1014.96 196.668C1082.14 248.223 1103.3 346.7 1083.09 464.333C1062.88 581.954 1001.31 718.653 903.182 846.542Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M739.41 364.426C895.117 406.148 1027.17 477.123 1114.81 558.134C1202.46 639.155 1245.62 730.16 1223.7 811.963C1201.78 893.766 1118.9 950.996 1002.49 977.338C886.084 1003.68 736.232 999.115 580.524 957.394C424.817 915.672 292.76 844.697 205.123 763.685C117.477 682.665 74.3115 591.66 96.2306 509.857C118.15 428.054 201.034 370.824 317.448 344.482C433.85 318.142 583.702 322.704 739.41 364.426Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M956.098 739.463C914.376 895.171 843.401 1027.23 762.39 1114.86C681.37 1202.51 590.365 1245.68 508.562 1223.76C426.759 1201.84 369.529 1118.95 343.186 1002.54C316.847 886.138 321.409 736.286 363.131 580.578C404.853 424.871 475.828 292.814 556.839 205.177C637.859 117.531 728.864 74.3654 810.667 96.2844C892.47 118.203 949.7 201.088 976.043 317.502C1002.38 433.903 997.82 583.756 956.098 739.463Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M506.759 394.838C646.362 314.238 789.928 271.047 909.18 266.362C1028.45 261.677 1123.32 295.504 1165.66 368.847C1208.01 442.189 1189.87 541.266 1126.18 642.21C1062.49 743.143 953.305 845.878 813.701 926.478C674.097 1007.08 530.532 1050.27 411.28 1054.95C292.015 1059.64 197.142 1025.81 154.798 952.47C112.453 879.127 130.594 780.051 194.284 679.107C257.967 578.174 367.155 475.439 506.759 394.838Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M925.172 506.804C1005.77 646.408 1048.96 789.973 1053.65 909.225C1058.33 1028.49 1024.51 1123.36 951.163 1165.71C877.821 1208.05 778.745 1189.91 677.8 1126.22C576.868 1062.54 474.132 953.35 393.532 813.746C312.932 674.143 269.74 530.577 265.055 411.325C260.37 292.06 294.198 197.187 367.54 154.843C440.883 112.498 539.959 130.639 640.903 194.329C741.836 258.012 844.572 367.2 925.172 506.804Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M961.64 606.864C989.632 765.616 981.116 915.295 944.732 1028.96C908.344 1142.63 844.107 1220.21 760.705 1234.92C677.303 1249.63 590.407 1198.69 517.334 1104.32C444.269 1009.96 385.073 872.216 357.081 713.464C329.089 554.713 337.604 405.033 373.989 291.37C410.377 177.695 474.613 100.114 558.015 85.4078C641.417 70.7017 728.314 121.634 801.387 216.009C874.452 310.372 933.648 448.113 961.64 606.864Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M713.321 961.747C554.569 989.739 404.89 981.224 291.227 944.839C177.552 908.451 99.9705 844.215 85.2645 760.813C70.5585 677.411 121.491 590.514 215.865 517.441C310.229 444.376 447.969 385.18 606.721 357.188C765.472 329.196 915.152 337.712 1028.81 374.096C1142.49 410.484 1220.07 474.72 1234.78 558.122C1249.48 641.524 1198.55 728.421 1104.18 801.494C1009.81 874.559 872.072 933.755 713.321 961.747Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M835.735 409.229C967.783 501.69 1067.6 613.55 1122.25 719.65C1176.9 825.761 1186.33 926.041 1137.76 995.414C1089.18 1064.79 991.721 1090.22 873.318 1075.15C754.928 1060.09 615.673 1004.55 483.625 912.094C351.578 819.633 251.76 707.772 197.116 601.673C142.466 495.562 133.029 395.282 181.604 325.909C230.18 256.536 327.64 231.106 446.043 246.168C564.433 261.228 703.688 316.768 835.735 409.229Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M911.079 835.757C818.619 967.805 706.758 1067.62 600.659 1122.27C494.548 1176.92 394.267 1186.35 324.895 1137.78C255.522 1089.2 230.091 991.743 245.153 873.34C260.214 754.95 315.754 615.695 408.214 483.648C500.675 351.6 612.536 251.782 718.635 197.138C824.746 142.488 925.026 133.051 994.399 181.627C1063.77 230.202 1089.2 327.662 1074.14 446.065C1059.08 564.455 1003.54 703.71 911.079 835.757Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M726.436 361.246C883.815 396.136 1018.84 461.283 1109.93 538.395C1201.03 615.515 1248.12 704.551 1229.79 787.232C1211.46 869.913 1131.15 930.704 1016 962.099C900.855 993.491 750.946 995.47 593.567 960.579C436.187 925.689 301.16 860.542 210.073 783.431C118.976 706.31 71.8825 617.275 90.2124 534.594C108.542 451.912 188.852 391.122 304.006 359.726C419.148 328.334 569.056 326.356 726.436 361.246Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M959.278 726.473C924.388 883.852 859.24 1018.88 782.129 1109.97C705.009 1201.06 615.973 1248.16 533.292 1229.83C450.611 1211.5 389.82 1131.19 358.425 1016.03C327.033 900.892 325.054 750.983 359.944 593.604C394.835 436.224 459.982 301.197 537.093 210.11C614.213 119.014 703.249 71.9197 785.93 90.2497C868.611 108.58 929.402 188.889 960.798 304.043C992.189 419.185 994.168 569.093 959.278 726.473Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M495.333 401.776C631.289 315.163 772.833 265.75 891.767 255.868C1010.71 245.985 1106.97 275.643 1152.48 347.068C1197.98 418.494 1184.18 518.267 1124.95 621.893C1065.73 725.508 961.129 832.908 825.174 919.521C689.218 1006.13 547.674 1055.55 428.739 1065.43C309.792 1075.31 213.534 1045.65 168.031 974.229C122.528 902.803 136.329 803.03 195.556 699.404C254.776 595.789 359.378 488.389 495.333 401.776Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M918.233 495.385C1004.85 631.34 1054.26 772.885 1064.14 891.819C1074.02 1010.77 1044.37 1107.02 972.941 1152.53C901.515 1198.03 801.742 1184.23 698.116 1125C594.501 1065.78 487.101 961.181 400.488 825.225C313.875 689.27 264.463 547.726 254.58 428.791C244.697 309.844 274.355 213.586 345.78 168.083C417.206 122.58 516.979 136.381 620.605 195.608C724.22 254.828 831.62 359.43 918.233 495.385Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M959.03 593.759C993.92 751.138 991.942 901.047 960.55 1016.19C929.155 1131.34 868.364 1211.65 785.683 1229.98C703.001 1248.31 613.966 1201.22 536.846 1110.12C459.734 1019.03 394.587 884.007 359.697 726.628C324.806 569.248 326.785 419.34 358.177 304.198C389.572 189.044 450.363 108.734 533.044 90.4044C615.725 72.0744 704.761 119.168 781.881 210.265C858.993 301.352 924.14 436.379 959.03 593.759Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M726.432 959.135C569.053 994.025 419.144 992.046 304.002 960.655C188.848 929.259 108.539 868.468 90.2086 785.787C71.8787 703.106 118.973 614.07 210.069 536.95C301.156 459.839 436.184 394.692 593.563 359.801C750.942 324.911 900.851 326.89 1015.99 358.281C1131.15 389.677 1211.46 450.468 1229.79 533.149C1248.12 615.83 1201.02 704.866 1109.93 781.986C1018.84 859.097 883.811 924.245 726.432 959.135Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M824.625 401.802C960.58 488.415 1065.18 595.816 1124.4 699.43C1183.63 803.057 1197.43 902.83 1151.93 974.255C1106.42 1045.68 1010.17 1075.34 891.219 1065.46C772.285 1055.57 630.74 1006.16 494.785 919.548C358.83 832.935 254.227 725.534 195.007 621.92C135.781 518.293 121.979 418.52 167.482 347.095C212.985 275.669 309.243 246.012 428.191 255.895C547.125 265.777 688.67 315.189 824.625 401.802Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M918.506 824.638C831.893 960.593 724.493 1065.2 620.878 1124.42C517.252 1183.64 417.479 1197.44 346.053 1151.94C274.628 1106.44 244.97 1010.18 254.854 891.231C264.736 772.297 314.148 630.753 400.761 494.797C487.374 358.842 594.775 254.24 698.389 195.02C802.015 135.794 901.788 121.992 973.214 167.495C1044.64 212.998 1074.3 309.256 1064.41 428.204C1054.53 547.138 1005.12 688.682 918.506 824.638Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M713.34 358.633C872.092 386.625 1009.83 445.821 1104.2 518.886C1198.57 591.959 1249.5 678.856 1234.8 762.258C1220.09 845.659 1142.51 909.896 1028.83 946.284C915.172 982.668 765.492 991.184 606.74 963.192C447.989 935.2 310.249 876.004 215.885 802.939C121.511 729.866 70.578 642.969 85.284 559.567C99.9901 476.165 177.572 411.929 291.247 375.541C404.909 339.156 554.589 330.641 713.34 358.633Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M961.892 713.357C933.9 872.108 874.705 1009.85 801.64 1104.21C728.567 1198.59 641.67 1249.52 558.268 1234.81C474.866 1220.11 410.63 1142.53 374.242 1028.85C337.857 915.188 329.342 765.508 357.334 606.757C385.326 448.005 444.521 310.265 517.586 215.901C590.66 121.527 677.556 70.5943 760.958 85.3004C844.36 100.006 908.596 177.588 944.985 291.263C981.369 404.926 989.885 554.605 961.892 713.357Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M484.224 409.204C616.271 316.743 755.526 261.203 873.916 246.143C992.319 231.08 1089.78 256.511 1138.35 325.884C1186.93 395.257 1177.49 495.537 1122.84 601.648C1068.2 707.747 968.381 819.608 836.334 912.069C704.286 1004.53 565.031 1060.07 446.641 1075.13C328.238 1090.19 230.778 1064.76 182.203 995.388C133.627 926.016 143.064 825.735 197.714 719.624C252.358 613.525 352.176 501.664 484.224 409.204Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M910.806 484.278C1003.27 616.326 1058.81 755.581 1073.87 873.971C1088.93 992.374 1063.5 1089.83 994.125 1138.41C924.753 1186.98 824.472 1177.55 718.361 1122.9C612.262 1068.25 500.401 968.436 407.941 836.388C315.48 704.34 259.94 565.086 244.88 446.696C229.817 328.293 255.248 230.833 324.621 182.257C393.994 133.682 494.274 143.118 600.385 197.769C706.484 252.412 818.345 352.231 910.806 484.278Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M955.853 580.778C997.575 736.486 1002.14 886.338 975.798 1002.74C949.455 1119.15 892.225 1202.04 810.422 1223.96C728.619 1245.88 637.614 1202.71 556.594 1115.06C475.582 1027.43 404.607 895.371 362.886 739.663C321.164 583.955 316.602 434.103 342.941 317.702C369.284 201.288 426.514 118.403 508.317 96.4838C590.119 74.5648 681.125 117.73 762.145 205.376C843.156 293.013 914.131 425.07 955.853 580.778Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M739.41 955.953C583.702 997.675 433.85 1002.24 317.449 975.898C201.035 949.556 118.15 892.325 96.2312 810.523C74.3121 728.72 117.478 637.715 205.124 556.694C292.76 475.683 424.817 404.708 580.525 362.986C736.233 321.264 886.085 316.702 1002.49 343.042C1118.9 369.384 1201.79 426.614 1223.7 508.417C1245.62 590.22 1202.46 681.225 1114.81 762.245C1027.17 843.257 895.118 914.232 739.41 955.953Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M813.201 394.866C952.804 475.466 1061.99 578.201 1125.68 679.134C1189.37 780.078 1207.51 879.155 1165.16 952.497C1122.82 1025.84 1027.94 1059.67 908.679 1054.98C789.427 1050.3 645.862 1007.11 506.258 926.506C366.655 845.905 257.467 743.17 193.784 642.237C130.093 541.293 111.953 442.217 154.297 368.874C196.641 295.532 291.514 261.704 410.78 266.389C530.032 271.074 673.597 314.265 813.201 394.866Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M925.441 813.208C844.841 952.811 742.105 1062 641.172 1125.68C540.228 1189.37 441.152 1207.51 367.81 1165.17C294.467 1122.82 260.639 1027.95 265.325 908.686C270.009 789.434 313.201 645.869 393.801 506.265C474.401 366.661 577.137 257.474 678.07 193.791C779.014 130.1 878.09 111.96 951.433 154.304C1024.78 196.648 1058.6 291.521 1053.92 410.787C1049.23 530.039 1006.04 673.604 925.441 813.208Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M700.135 356.594C859.957 377.634 1000.15 430.765 1097.61 499.645C1195.08 568.532 1249.76 653.124 1238.7 737.088C1227.65 821.052 1152.94 888.611 1040.96 929.924C928.994 971.231 779.829 986.267 620.007 965.227C460.186 944.186 319.994 891.055 222.533 822.175C125.062 753.288 70.3871 668.696 81.4411 584.732C92.4952 500.768 167.201 433.209 279.181 391.897C391.148 350.589 540.314 335.553 700.135 356.594Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M963.924 700.136C942.883 859.958 889.752 1000.15 820.873 1097.61C751.986 1195.08 667.393 1249.76 583.429 1238.7C499.465 1227.65 431.906 1152.94 390.594 1040.96C349.286 928.995 334.25 779.83 355.291 620.008C376.332 460.187 429.463 319.995 498.342 222.534C567.229 125.063 651.821 70.3882 735.785 81.4422C819.749 92.4963 887.309 167.202 928.621 279.182C969.928 391.149 984.965 540.315 963.924 700.136Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M473.447 417.109C601.336 318.977 738.035 257.416 855.655 237.205C973.289 216.993 1071.77 238.148 1123.32 305.336C1174.88 372.524 1169.82 473.12 1119.85 581.514C1069.89 689.896 975.045 806.004 847.156 904.137C719.267 1002.27 582.568 1063.83 464.947 1084.04C347.314 1104.25 248.837 1083.1 197.282 1015.91C145.727 948.722 150.78 848.126 200.75 739.732C250.714 631.35 345.558 515.242 473.447 417.109Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M902.898 473.504C1001.03 601.393 1062.59 738.093 1082.8 855.713C1103.01 973.347 1081.86 1071.82 1014.67 1123.38C947.483 1174.93 846.887 1169.88 738.493 1119.91C630.111 1069.95 514.003 975.103 415.87 847.214C317.738 719.325 256.177 582.625 235.966 465.005C215.754 347.372 236.909 248.895 304.097 197.34C371.285 145.785 471.881 150.838 580.275 200.808C688.657 250.772 804.765 345.615 902.898 473.504Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M952.113 567.948C1000.59 721.687 1011.68 871.198 990.444 988.637C969.204 1106.09 915.644 1191.39 834.875 1216.86C754.106 1242.32 661.305 1203.17 576.539 1119.14C491.782 1035.12 415.114 906.286 366.64 752.546C318.166 598.807 307.072 449.296 328.309 331.857C349.548 214.405 403.109 129.102 483.877 103.636C564.646 78.1695 657.448 117.324 742.214 201.353C826.971 285.372 903.639 414.208 952.113 567.948Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M752.238 952.209C598.498 1000.68 448.987 1011.78 331.548 990.54C214.096 969.301 128.793 915.741 103.327 834.972C77.8608 754.203 117.015 661.402 201.044 576.635C285.064 491.878 413.899 415.211 567.639 366.737C721.378 318.263 870.889 307.168 988.328 328.406C1105.78 349.645 1191.08 403.205 1216.55 483.974C1242.02 564.743 1202.86 657.545 1118.83 742.311C1034.81 827.068 905.977 903.735 752.238 952.209Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M801.483 388.437C944.47 462.871 1058.03 560.746 1126.06 658.805C1194.09 756.875 1216.54 855.065 1177.43 930.185C1138.33 1005.3 1045.02 1043.24 925.665 1043.76C806.322 1044.28 661.009 1007.39 518.023 932.959C375.036 858.525 261.471 760.65 193.446 662.591C125.413 564.521 102.968 466.33 142.073 391.211C181.178 316.091 274.485 278.157 393.841 277.636C513.184 277.114 658.497 314.002 801.483 388.437Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M931.866 801.484C857.432 944.47 759.557 1058.04 661.498 1126.06C563.428 1194.09 465.238 1216.54 390.118 1177.43C314.998 1138.33 277.064 1045.02 276.543 925.665C276.021 806.322 312.91 661.01 387.344 518.023C461.778 375.037 559.653 261.472 657.712 193.446C755.782 125.414 853.972 102.969 929.092 142.074C1004.21 181.178 1042.15 274.485 1042.67 393.842C1043.19 513.184 1006.3 658.497 931.866 801.484Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M686.861 355.131C847.448 369.181 989.823 416.146 1090.2 480.709C1190.58 545.278 1248.89 627.405 1241.51 711.772C1234.13 796.138 1162.44 866.891 1052.37 913.049C942.312 959.201 793.944 980.729 633.357 966.68C472.77 952.63 330.395 905.665 230.022 841.103C129.638 776.533 71.3259 694.406 78.707 610.039C86.0881 525.673 157.776 454.92 267.847 408.762C377.906 362.61 526.274 341.081 686.861 355.131Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M965.379 686.841C951.329 847.428 904.364 989.804 839.801 1090.18C775.232 1190.56 693.105 1248.87 608.738 1241.49C524.372 1234.11 453.619 1162.42 407.461 1052.35C361.309 942.293 339.78 793.925 353.83 633.338C367.879 472.751 414.845 330.375 479.407 230.002C543.977 129.619 626.104 71.3063 710.47 78.6874C794.837 86.0685 865.59 157.756 911.748 267.827C957.9 377.886 979.428 526.254 965.379 686.841Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M463.027 425.478C586.513 321.861 720.397 254.395 837.024 229.074C953.664 203.75 1052.97 220.589 1107.41 285.464C1161.84 350.339 1161.18 451.061 1115.99 561.531C1070.8 671.989 981.111 792.124 857.624 895.741C734.137 999.359 600.253 1066.82 483.626 1092.15C366.987 1117.47 267.681 1100.63 213.244 1035.76C158.807 970.88 159.468 870.159 204.662 759.689C249.851 649.231 339.54 529.096 463.027 425.478Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M894.529 463.089C998.146 586.576 1065.61 720.46 1090.93 837.086C1116.26 953.726 1099.42 1053.03 1034.54 1107.47C969.667 1161.91 868.946 1161.24 758.476 1116.05C648.018 1070.86 527.883 981.173 424.266 857.686C320.648 734.2 253.183 600.315 227.861 483.689C202.537 367.049 219.376 267.743 284.252 213.306C349.127 158.87 449.848 159.53 560.318 204.724C670.776 249.913 790.911 339.602 894.529 463.089Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M947.814 555.295C1002.95 706.774 1020.55 855.658 1004.46 973.912C988.362 1092.18 938.574 1179.74 858.993 1208.7C779.412 1237.67 684.991 1202.6 596.64 1122.35C508.299 1042.1 426.084 916.735 370.95 765.256C315.817 613.777 298.211 464.893 314.305 346.639C330.402 228.372 380.19 140.814 459.771 111.849C539.352 82.884 633.773 117.953 722.124 198.205C810.465 278.447 892.68 403.816 947.814 555.295Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M764.896 947.906C613.418 1003.04 464.533 1020.65 346.279 1004.55C228.012 988.455 140.455 938.667 111.49 859.086C82.5246 779.504 117.594 685.083 197.845 596.733C278.088 508.392 403.457 426.177 554.935 371.043C706.414 315.909 855.299 298.304 973.553 314.398C1091.82 330.494 1179.38 380.283 1208.34 459.864C1237.31 539.445 1202.24 633.866 1121.99 722.217C1041.74 810.558 916.375 892.773 764.896 947.906Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M789.498 382.524C935.596 450.651 1053.32 543.479 1125.56 638.477C1197.81 733.486 1224.51 830.604 1188.72 907.358C1152.93 984.112 1061.37 1026.08 942.147 1031.81C822.94 1037.53 676.157 1007.02 530.059 938.893C383.962 870.767 266.236 777.938 193.998 682.94C121.752 587.931 95.0458 490.813 130.837 414.059C166.628 337.305 258.191 295.337 377.411 289.61C496.618 283.883 643.401 314.398 789.498 382.524Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M937.776 789.49C869.649 935.587 776.821 1053.31 681.823 1125.55C586.814 1197.8 489.695 1224.5 412.941 1188.71C336.188 1152.92 294.22 1061.36 288.493 942.138C282.766 822.932 313.281 676.148 381.407 530.051C449.533 383.954 542.361 266.228 637.36 193.99C732.369 121.744 829.487 95.0376 906.241 130.829C982.995 166.62 1024.96 258.183 1030.69 377.403C1036.42 496.609 1005.9 643.393 937.776 789.49Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M673.536 354.25C834.583 361.282 978.871 401.992 1081.96 462.115C1185.07 522.245 1246.91 601.75 1243.21 686.358C1239.52 770.966 1170.99 844.779 1063.03 895.694C955.093 946.603 807.805 974.582 646.758 967.551C485.711 960.52 341.423 919.809 238.33 859.686C135.225 799.557 73.3855 720.051 77.0796 635.443C80.7736 550.835 149.307 477.022 257.26 426.108C365.201 375.199 512.489 347.219 673.536 354.25Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M966.26 673.496C959.229 834.543 918.518 978.831 858.395 1081.92C798.266 1185.03 718.76 1246.87 634.153 1243.17C549.545 1239.48 475.731 1170.95 424.817 1062.99C373.908 955.053 345.928 807.765 352.96 646.718C359.991 485.671 400.701 341.383 460.824 238.289C520.954 135.185 600.459 73.3455 685.067 77.0395C769.675 80.7336 843.488 149.267 894.403 257.22C945.312 365.161 973.292 512.449 966.26 673.496Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M452.975 434.295C571.825 325.389 702.639 252.148 818.05 221.764C933.474 191.376 1033.42 203.868 1090.63 266.307C1147.85 328.746 1151.58 429.4 1111.25 541.736C1070.92 654.06 986.56 777.993 867.71 886.898C748.861 995.804 618.047 1069.04 502.635 1099.43C387.211 1129.82 287.266 1117.33 230.051 1054.89C172.836 992.448 169.103 891.794 209.435 779.457C249.763 667.133 334.126 543.2 452.975 434.295Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
      <path
        d="M885.706 453.046C994.611 571.895 1067.85 702.709 1098.24 818.12C1128.62 933.544 1116.13 1033.49 1053.69 1090.7C991.255 1147.92 890.601 1151.65 778.264 1111.32C665.941 1070.99 542.008 986.63 433.102 867.78C324.197 748.931 250.956 618.117 220.571 502.706C190.183 387.281 202.675 287.336 265.114 230.121C327.553 172.906 428.207 169.173 540.544 209.505C652.867 249.833 776.8 334.196 885.706 453.046Z"
        stroke="#D8E0FF"
        strokeWidth="0.508604"
      />
    </svg>
  );
};

export default Tora;
