import styled from 'styled-components';
import StyledContent from '../UI/StyledContent';
import StyledH2 from '../UI/StyledH2';
import RoadmapCard from './RoadmapCard';
import StyledH3 from '../UI/StyledH3';
import roadmapSvg from '../../assets/images/roadmap.svg';

const StyledContentRoadmap = styled(StyledContent)({
  flexDirection: 'column',
  '@media (max-width: 600px)': {
    padding: '0px 30px'
  }
});

const StyledCardWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  marginTop: '50px',
  flexWrap: 'wrap',
  background: `url('${roadmapSvg}')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '100% 85%',
  backgroundPosition: 'center',
  padding: '100px 0px',
  '@media (max-width: 1255px)': {
    background: 'none',
    padding: '10px 0px'
  }
});

const StyledCardContent = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
  '@media (max-width: 600px)': {
    flexWrap: 'nowrap',
    overflowY: 'scroll'
  }
});

const StyledText = styled.p({
  fontFamily: 'Gilroy',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '18.56px',
  textAlign: 'left',
  marginTop: '22px'
});

const StyledSpan = styled.span({
  fontFamily: 'Gilroy',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '19.34px',
  textAlign: 'left',
  display: 'block'
});

const Roadmap = () => {
  return (
    <StyledContentRoadmap>
      <StyledH2>Road Map</StyledH2>
      <StyledCardWrapper>
        <StyledCardContent>
          <RoadmapCard date="January, 2024">
            <StyledH3>Business planning</StyledH3>
            <StyledText>
              <StyledSpan>Product ideation:</StyledSpan>features, purposes, alternatives, competitor
              research
            </StyledText>
            <StyledText>
              <StyledSpan>Product Design:</StyledSpan>monetization models, business model, design
              UX/UI
            </StyledText>
            <StyledText>
              <StyledSpan>Pre Development stage:</StyledSpan>definition of tools for
              productdevelopment, supported operating systems, finding best paymentsolutions and
              further vector ofproduct development (newfeatures such as AR/VRimplementation... etc.)
            </StyledText>
          </RoadmapCard>
          <RoadmapCard date="TBA">
            <StyledH3>MVP</StyledH3>
            <StyledText>
              <StyledSpan>Q/A , Focus Groups Testing</StyledSpan>
            </StyledText>
            <StyledText>
              <StyledSpan>Marketing campaign concepts, brand development</StyledSpan>
            </StyledText>
            <StyledText>
              <StyledSpan>Product includes:</StyledSpan>
              <ul>
                <li>IOS/Android app</li>
                <li>Main user, influencer functionality</li>
                <li>Payments</li>
              </ul>
            </StyledText>
          </RoadmapCard>
          <RoadmapCard date="TBA">
            <StyledH3>MMP</StyledH3>
            <StyledText>
              <StyledSpan>Q/A , Focus Groups, test comfort, durability, functionality</StyledSpan>
            </StyledText>
            <StyledText>
              <StyledSpan>Market analysis: branding, campaign running</StyledSpan>
            </StyledText>
            <StyledText>
              <StyledSpan>Product includes:</StyledSpan>
              <ul>
                <li>IOS/Android app</li>
                <li>Main Product Website</li>
                <li>Landing Pages for Marketing</li>
                <li>Admin/moderator Functionality</li>
                <li>In App Statistics</li>
                <li>Ads statistic metrics</li>
                <li>Main user functionality</li>
                <li>Influencer user functionality</li>
              </ul>
            </StyledText>
          </RoadmapCard>
          <RoadmapCard date="TBA">
            <StyledH3>Release</StyledH3>
            <StyledText>Product testing continued: reiterate,optimize, finalize</StyledText>
            <StyledText>Release marketing campaign</StyledText>
            <StyledText>
              <StyledSpan>Product includes:</StyledSpan>
              <ul>
                <li>Advertising manager cabinet</li>
                <li>Additional monetization features (Stories, Blog page)</li>
              </ul>
            </StyledText>
          </RoadmapCard>
        </StyledCardContent>
      </StyledCardWrapper>
    </StyledContentRoadmap>
  );
};

export default Roadmap;
