import styled from 'styled-components';
import PropTypes from 'prop-types';
import StyledH4 from '../UI/StyledH4';
import StyledButton from '../UI/StyledButton';
import { NavHashLink } from 'react-router-hash-link';

const StyledCard = styled.div({
  background: '#F5F5F5',
  border: '1px solid #C3C3C3',
  borderRadius: '20px',
  maxWidth: '240px',
  display: 'flex',
  flexDirection: 'column',
  padding: '30px',
  justifyContent: 'space-between',
  minHeight: '490px',
  '@media (max-width: 600px)': {
    maxWidth: 'initial',
    minWidth: '400px',
    minHeight: 'initial'
  }
});

const StyleMoreButton = styled(StyledButton)({
  marginTop: '21px',
  width: '100%'
});

const RoadmapCard = ({ date, children }) => {
  return (
    <StyledCard>
      <StyledH4>{date}</StyledH4>
      <div>
        {children}
        <NavHashLink smooth to="#contacts">
          <StyleMoreButton>More info</StyleMoreButton>
        </NavHashLink>
      </div>
    </StyledCard>
  );
};

RoadmapCard.propTypes = {
  date: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default RoadmapCard;
