import styled from 'styled-components';
import Roadmap from '../Roadmap';
import About from './About';
import Embrace from './Embrace';
import Functionality from './Functionality';
import TeamMembers from './TeamMembers';

const RoadmapWrapper = styled.div({
  marginTop: '100px'
});

const UserPage = () => {
  return (
    <>
      <Embrace />
      <About />
      <Functionality />
      <RoadmapWrapper>
        <Roadmap />
      </RoadmapWrapper>
      <TeamMembers />
    </>
  );
};

export default UserPage;
