import styled from 'styled-components';

const StyledH5 = styled.h5({
  fontFamily: 'Gilroy',
  fontSize: '50px',
  fontWeight: 600,
  lineHeight: '59.65px',
  textAlign: 'left',
  color: '#2F2F2F',
  '@media (max-width: 600px)': {
    fontSize: '40px',
    textAlign: 'center'
  }
});

export default StyledH5;
