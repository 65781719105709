const OpportunitiesSvg = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="60" height="60" rx="20" fill="#2F2F2F" />
      <g clipPath="url(#clip0_412_149)">
        <path
          d="M44.957 26.5581C44.8536 26.2399 44.5786 26.0079 44.2475 25.9599L34.9473 24.6084L30.7881 16.181C30.6401 15.8811 30.3345 15.6911 30 15.6911C29.6655 15.6911 29.3599 15.8811 29.2118 16.181L25.0528 24.6084L15.7525 25.9599C15.4215 26.0079 15.1464 26.2399 15.043 26.5581C14.9397 26.8762 15.0259 27.2255 15.2655 27.459L21.9951 34.0188L20.4064 43.2814C20.3626 43.5367 20.4337 43.7984 20.6006 43.9965C20.7676 44.1946 21.0135 44.3089 21.2726 44.3089H38.7274C38.9865 44.3089 39.2323 44.1946 39.3993 43.9965C39.5664 43.7984 39.6374 43.5367 39.5936 43.2814L38.005 34.0188L44.7345 27.459C44.9741 27.2255 45.0603 26.8762 44.957 26.5581ZM30 25.6057C32.042 25.6057 33.7032 27.2669 33.7032 29.3089C33.7032 31.3507 32.0419 33.0119 30 33.0119C27.9581 33.0119 26.2969 31.3507 26.2969 29.3089C26.2969 27.2669 27.9581 25.6057 30 25.6057ZM23.532 42.5511C23.9626 39.3661 26.6988 36.9026 30 36.9026C33.3011 36.9026 36.0373 39.3661 36.468 42.5511H23.532Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_412_149">
          <rect width="30" height="30" fill="white" transform="translate(15 15)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OpportunitiesSvg;
