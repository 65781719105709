import styled from 'styled-components';
import StyledContent from '../UI/StyledContent';
import Monetezation1 from '../svg/Monetezation1';
import Monetezation2 from '../svg/Monetezation2';
import Monetezation3 from '../svg/Monetezation3';
import StyledParagraph from '../UI/StyledParagraph';
import MonetizationCircle from '../svg/MonetizationCircle';

const MonetizationWrapper = styled.div({
  position: 'relative',
  overflow: 'hidden',
  marginTop: '80px'
});

const MonetizationContent = styled(StyledContent)({
  flexDirection: 'column',
  '@media (max-width: 600px)': {
    padding: '0px 30px'
  }
});

const StyledH3 = styled.h3({
  fontFamily: 'Gilroy',
  fontSize: '40px',
  fontWeight: 600,
  lineHeight: '47.72px',
  textAlign: 'center'
});

const CardsWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '29px',
  gap: '25px',
  flexWrap: 'wrap',
  '@media (max-width: 1273px)': {
    justifyContent: 'center'
  }
});

const Card = styled.div({
  borderRadius: '10px',
  padding: '20px',
  background: '#F5F5F5',
  border: '1px solid #C3C3C3',
  width: '360px',
  height: '250px',
  '> p': {
    color: '#2F2F2F',
    marginTop: '10px'
  },
  '@media (max-width: 1273px)': {
    marginBottom: '0px !important'
  },
  '@media (max-width: 600px)': {
    width: 'auto',
    height: 'auto',
    '> p': {
      textAlign: 'left'
    }
  }
});

const MonetizationCircleSvg = styled.div({
  position: 'absolute',
  zIndex: -1,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%)'
});

const MonetizationOptions = () => {
  return (
    <MonetizationWrapper>
      <MonetizationCircleSvg>
        <MonetizationCircle />
      </MonetizationCircleSvg>
      <MonetizationContent>
        <StyledH3>Monetization options</StyledH3>
        <CardsWrapper>
          <Card>
            <Monetezation3 />
            <StyledParagraph style={{ fontWeight: 600 }}>
              We charge a small commission on paid subscriptions from each user.
            </StyledParagraph>
          </Card>
          <Card style={{ marginBottom: '78px' }}>
            <Monetezation2 />
            <StyledParagraph style={{ fontWeight: 600 }}>
              An ad cabinet will be introduced - where the accuracy of ad customization - will be
              something new in the market, due to accurate audience segmentation.
            </StyledParagraph>
          </Card>
          <Card>
            <Monetezation1 />
            <StyledParagraph style={{ fontWeight: 600 }}>
              There will be opportunities for paid placement in our blog, where there will be a lot
              of useful and interesting articles and videos. Also in the future we plan to create a
              large information portal about the life of celebrities and health.
            </StyledParagraph>
          </Card>
        </CardsWrapper>
      </MonetizationContent>
    </MonetizationWrapper>
  );
};

export default MonetizationOptions;
