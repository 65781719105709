import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header/Header';
import GlobalFonts from './style';
import UserPage from './components/UserPage';
import ContactForm from './components/ContactForm/ContactForm';
import Footer from './components/Footer';
import InfluencerPage from './components/InfluencerPage';
import InvestorPage from './components/InvestorPage';
import NavigationWrapper from './components/Header/NavigationWrapper';

const theme = {
  mainGrey: '#F5F5F5'
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalFonts />
      <Router>
        <NavigationWrapper>
          <Header />
          <Routes>
            <Route exact path="/" element={<UserPage />} />
            <Route exact path="influencer" element={<InfluencerPage />} />
            <Route exact path="investor" element={<InvestorPage />} />
          </Routes>
        </NavigationWrapper>
      </Router>
      <ContactForm />
      <Footer />
    </ThemeProvider>
  );
}

export default App;
