const RightStars = () => {
  return (
    <svg
      width="143"
      height="143"
      viewBox="0 0 143 143"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_352_672)">
        <path
          d="M90.5504 53.5149C80.3801 45.2126 76.0821 31.6751 79.6013 19.0268C80.0331 17.474 79.3176 15.8307 77.8861 15.0891C76.4546 14.3476 74.6995 14.711 73.6803 15.9596C65.3782 26.13 51.8408 30.4278 39.1926 26.9088C37.6397 26.4767 35.9962 27.1923 35.2547 28.6236C34.5132 30.0549 34.8765 31.8102 36.1254 32.8295C46.2955 41.1317 50.5932 54.6688 47.0743 67.3171C46.6422 68.8699 47.3577 70.5134 48.7892 71.255C50.2205 71.9965 51.9758 71.6332 52.9951 70.3843C61.2974 60.214 74.8349 55.9163 87.483 59.4356C89.0359 59.8676 90.6794 59.1522 91.4209 57.7207C92.1622 56.2893 91.7989 54.534 90.5504 53.5149Z"
          fill="#D6DFFF"
        />
        <path
          d="M59.6636 100.893C54.8717 96.9814 52.8467 90.6032 54.5046 84.6434C54.9367 83.0906 54.2212 81.4471 52.7897 80.7055C51.3584 79.9641 49.6033 80.3272 48.5839 81.576C44.672 86.3679 38.2937 88.3928 32.3344 86.7349C30.7815 86.3028 29.1379 87.0185 28.3965 88.4498C27.655 89.8811 28.0183 91.6364 29.2672 92.6557C34.0588 96.5672 36.0838 102.946 34.4259 108.905C33.9938 110.458 34.7093 112.101 36.1407 112.843C37.572 113.584 39.3272 113.221 40.3466 111.972C44.2585 107.18 50.6369 105.156 56.5964 106.814C58.1492 107.246 59.7928 106.53 60.5343 105.099C61.2757 103.667 60.9123 101.912 59.6636 100.893Z"
          fill="#D6DFFF"
        />
        <path
          d="M115.038 94.0569C108.561 88.7696 105.824 80.1484 108.065 72.0936C108.497 70.5408 107.782 68.8973 106.351 68.1557C104.919 67.4143 103.164 67.7774 102.145 69.0262C96.8574 75.5032 88.2364 78.2402 80.1814 75.9992C78.6285 75.5671 76.985 76.2827 76.2435 77.714C75.502 79.1453 75.8654 80.9004 77.1142 81.9199C83.591 87.2071 86.328 95.8281 84.087 103.883C83.6549 105.436 84.3703 107.079 85.8018 107.821C87.2331 108.562 88.9883 108.199 90.0077 106.95C95.2952 100.473 103.916 97.7364 111.971 99.9776C113.524 100.409 115.167 99.694 115.909 98.2627C116.65 96.8314 116.287 95.0762 115.038 94.0569Z"
          fill="#D6DFFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_352_672">
          <rect
            width="106"
            height="106"
            fill="white"
            transform="translate(48.7578) rotate(27.386)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RightStars;
