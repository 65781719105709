import styled from 'styled-components';

const StyledParagraph = styled.p({
  fontFamily: 'Gilroy',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '23.2px',
  textAlign: 'left',
  color: 'white',
  '@media (max-width: 600px)': {
    fontSize: '16px',
    textAlign: 'center',
    lineHeight: '18.56px'
  }
});

export default StyledParagraph;
