const Circle2 = () => {
  return (
    <svg
      width="173"
      height="560"
      viewBox="0 0 173 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M888 114.5C888 360.543 689.214 560 444 560C198.786 560 0 360.543 0 114.5C0 -131.543 198.786 -331 444 -331C689.214 -331 888 -131.543 888 114.5ZM83.7523 114.5C83.7523 314.131 245.041 475.965 444 475.965C642.959 475.965 804.248 314.131 804.248 114.5C804.248 -85.1315 642.959 -246.965 444 -246.965C245.041 -246.965 83.7523 -85.1315 83.7523 114.5Z"
        fill="#F5F5F5"
      />
    </svg>
  );
};

export default Circle2;
