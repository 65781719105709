import { createGlobalStyle } from 'styled-components';

import GilroyRegularEot from './assets/fonts/Gilroy-Regular.eot';
import GilroyRegularWoff2 from './assets/fonts/Gilroy-Regular.woff2';
import GilroyRegularWoff from './assets/fonts/Gilroy-Regular.woff';
import GilroyRegularTtf from './assets/fonts/Gilroy-Regular.ttf';

import GilroyExtraboldItalicEot from './assets/fonts/Gilroy-ExtraboldItalic.eot';
import GilroyExtraboldItalicWoff2 from './assets/fonts/Gilroy-ExtraboldItalic.woff2';
import GilroyExtraboldItalicWoff from './assets/fonts/Gilroy-ExtraboldItalic.woff';
import GilroyExtraboldItalicTtf from './assets/fonts/Gilroy-ExtraboldItalic.ttf';

import GilroyBoldEot from './assets/fonts/Gilroy-Bold.eot';
import GilroyBoldWoff2 from './assets/fonts/Gilroy-Bold.woff2';
import GilroyBoldWoff from './assets/fonts/Gilroy-Bold.woff';
import GilroyBoldTtf from './assets/fonts/Gilroy-Bold.ttf';

import GilroyBlackEot from './assets/fonts/Gilroy-Black.eot';
import GilroyBlackWoff2 from './assets/fonts/Gilroy-Black.woff2';
import GilroyBlackWoff from './assets/fonts/Gilroy-Black.woff';
import GilroyBlackTtf from './assets/fonts/Gilroy-Black.ttf';

import GilroyLightEot from './assets/fonts/Gilroy-Light.eot';
import GilroyLightWoff2 from './assets/fonts/Gilroy-Light.woff2';
import GilroyLightWoff from './assets/fonts/Gilroy-Light.woff';
import GilroyLightTtf from './assets/fonts/Gilroy-Light.ttf';

import GilroySemiboldEot from './assets/fonts/Gilroy-Semibold.eot';
import GilroySemiboldWoff2 from './assets/fonts/Gilroy-Semibold.woff2';
import GilroySemiboldWoff from './assets/fonts/Gilroy-Semibold.woff';
import GilroySemiboldTtf from './assets/fonts/Gilroy-Semibold.ttf';

import GilroyMediumEot from './assets/fonts/Gilroy-Medium.eot';
import GilroyMediumWoff2 from './assets/fonts/Gilroy-Medium.woff2';
import GilroyMediumWoff from './assets/fonts/Gilroy-Medium.woff';
import GilroyMediumTtf from './assets/fonts/Gilroy-Medium.ttf';

import GilroyMediumItalicEot from './assets/fonts/Gilroy-MediumItalic.eot';
import GilroyMediumItalicWoff2 from './assets/fonts/Gilroy-MediumItalic.woff2';
import GilroyMediumItalicWoff from './assets/fonts/Gilroy-MediumItalic.woff';
import GilroyMediumItalicTtf from './assets/fonts/Gilroy-MediumItalic.ttf';

import GilroyBlackItalicEot from './assets/fonts/Gilroy-BlackItalic.eot';
import GilroyBlackItalicWoff2 from './assets/fonts/Gilroy-BlackItalic.woff2';
import GilroyBlackItalicWoff from './assets/fonts/Gilroy-BlackItalic.woff';
import GilroyBlackItalicTtf from './assets/fonts/Gilroy-BlackItalic.ttf';

import GilroyUltraLightEot from './assets/fonts/Gilroy-UltraLight.eot';
import GilroyUltraLightWoff2 from './assets/fonts/Gilroy-UltraLight.woff2';
import GilroyUltraLightWoff from './assets/fonts/Gilroy-UltraLight.woff';
import GilroyUltraLightTtf from './assets/fonts/Gilroy-UltraLight.ttf';

import GilroyRegularItalicEot from './assets/fonts/Gilroy-RegularItalic.eot';
import GilroyRegularItalicWoff2 from './assets/fonts/Gilroy-RegularItalic.woff2';
import GilroyRegularItalicWoff from './assets/fonts/Gilroy-RegularItalic.woff';
import GilroyRegularItalicTtf from './assets/fonts/Gilroy-RegularItalic.ttf';

import GilroySemiboldItalicEot from './assets/fonts/Gilroy-SemiboldItalic.eot';
import GilroySemiboldItalicWoff2 from './assets/fonts/Gilroy-SemiboldItalic.woff2';
import GilroySemiboldItalicWoff from './assets/fonts/Gilroy-SemiboldItalic.woff';
import GilroySemiboldItalicTtf from './assets/fonts/Gilroy-SemiboldItalic.ttf';

import GilroyHeavyItalicEot from './assets/fonts/Gilroy-HeavyItalic.eot';
import GilroyHeavyItalicWoff2 from './assets/fonts/Gilroy-HeavyItalic.woff2';
import GilroyHeavyItalicWoff from './assets/fonts/Gilroy-HeavyItalic.woff';
import GilroyHeavyItalicTtf from './assets/fonts/Gilroy-HeavyItalic.ttf';

import GilroyExtraboldEot from './assets/fonts/Gilroy-Extrabold.eot';
import GilroyExtraboldWoff2 from './assets/fonts/Gilroy-Extrabold.woff2';
import GilroyExtraboldWoff from './assets/fonts/Gilroy-Extrabold.woff';
import GilroyExtraboldTtf from './assets/fonts/Gilroy-Extrabold.ttf';

import GilroyBoldItalicEot from './assets/fonts/Gilroy-BoldItalic.eot';
import GilroyBoldItalicWoff2 from './assets/fonts/Gilroy-BoldItalic.woff2';
import GilroyBoldItalicWoff from './assets/fonts/Gilroy-BoldItalic.woff';
import GilroyBoldItalicTtf from './assets/fonts/Gilroy-BoldItalic.ttf';

import GilroyUltraLightItalicEot from './assets/fonts/Gilroy-UltraLightItalic.eot';
import GilroyUltraLightItalicWoff2 from './assets/fonts/Gilroy-UltraLightItalic.woff2';
import GilroyUltraLightItalicWoff from './assets/fonts/Gilroy-UltraLightItalic.woff';
import GilroyUltraLightItalicTtf from './assets/fonts/Gilroy-UltraLightItalic.ttf';

import GilroyLightItalicEot from './assets/fonts/Gilroy-LightItalic.eot';
import GilroyLightItalicWoff2 from './assets/fonts/Gilroy-LightItalic.woff2';
import GilroyLightItalicWoff from './assets/fonts/Gilroy-LightItalic.woff';
import GilroyLightItalicTtf from './assets/fonts/Gilroy-LightItalic.ttf';

import GilroyHeavyEot from './assets/fonts/Gilroy-Heavy.eot';
import GilroyHeavyWoff2 from './assets/fonts/Gilroy-Heavy.woff2';
import GilroyHeavyWoff from './assets/fonts/Gilroy-Heavy.woff';
import GilroyHeavyTtf from './assets/fonts/Gilroy-Heavy.ttf';

export default createGlobalStyle`

@font-face {
    font-family: 'Gilroy';
    src: url(${GilroyRegularEot});
    src: local('Gilroy Regular'), local('Gilroy-Regular'),
        url(${GilroyRegularEot}?#iefix) format('embedded-opentype'),
        url(${GilroyRegularWoff2}) format('woff2'),
        url(${GilroyRegularWoff}) format('woff'),
        url(${GilroyRegularTtf}) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(${GilroyExtraboldItalicEot});
    src: local('Gilroy Extrabold Italic'), local('Gilroy-ExtraboldItalic'),
        url(${GilroyExtraboldItalicEot}?#iefix) format('embedded-opentype'),
        url(${GilroyExtraboldItalicWoff2}) format('woff2'),
        url(${GilroyExtraboldItalicWoff}) format('woff'),
        url(${GilroyExtraboldItalicTtf}) format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(${GilroyBoldEot});
    src: local('Gilroy Bold'), local('Gilroy-Bold'),
        url(${GilroyBoldEot}?#iefix) format('embedded-opentype'),
        url(${GilroyBoldWoff2}) format('woff2'),
        url(${GilroyBoldWoff}) format('woff'),
        url(${GilroyBoldTtf}) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(${GilroyBlackEot});
    src: local('Gilroy Black'), local('Gilroy-Black'),
        url(${GilroyBlackEot}?#iefix) format('embedded-opentype'),
        url(${GilroyBlackWoff2}) format('woff2'),
        url(${GilroyBlackWoff}) format('woff'),
        url(${GilroyBlackTtf}) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(${GilroyLightEot});
    src: local('Gilroy Light'), local('Gilroy-Light'),
        url(${GilroyLightEot}?#iefix) format('embedded-opentype'),
        url(${GilroyLightWoff2}) format('woff2'),
        url(${GilroyLightWoff}) format('woff'),
        url(${GilroyLightTtf}) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(${GilroySemiboldEot});
    src: local('Gilroy Semibold'), local('Gilroy-Semibold'),
        url(${GilroySemiboldEot}?#iefix) format('embedded-opentype'),
        url(${GilroySemiboldWoff2}) format('woff2'),
        url(${GilroySemiboldWoff}) format('woff'),
        url(${GilroySemiboldTtf}) format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(${GilroyMediumEot});
    src: local('Gilroy Medium'), local('Gilroy-Medium'),
        url(${GilroyMediumEot}?#iefix) format('embedded-opentype'),
        url(${GilroyMediumWoff2}) format('woff2'),
        url(${GilroyMediumWoff}) format('woff'),
        url(${GilroyMediumTtf}) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(${GilroyMediumItalicEot});
    src: local('Gilroy Medium Italic'), local('Gilroy-MediumItalic'),
        url(${GilroyMediumItalicEot}?#iefix) format('embedded-opentype'),
        url(${GilroyMediumItalicWoff2}) format('woff2'),
        url(${GilroyMediumItalicWoff}) format('woff'),
        url(${GilroyMediumItalicTtf}) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(${GilroyBlackItalicEot});
    src: local('Gilroy Black Italic'), local('Gilroy-BlackItalic'),
        url(${GilroyBlackItalicEot}?#iefix) format('embedded-opentype'),
        url(${GilroyBlackItalicWoff2}) format('woff2'),
        url(${GilroyBlackItalicWoff}) format('woff'),
        url(${GilroyBlackItalicTtf}) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(${GilroyUltraLightEot});
    src: local('Gilroy UltraLight'), local('Gilroy-UltraLight'),
        url(${GilroyUltraLightEot}?#iefix) format('embedded-opentype'),
        url(${GilroyUltraLightWoff2}) format('woff2'),
        url(${GilroyUltraLightWoff}) format('woff'),
        url(${GilroyUltraLightTtf}) format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(${GilroyRegularItalicEot});
    src: local('Gilroy Regular Italic'), local('Gilroy-RegularItalic'),
        url(${GilroyRegularItalicEot}?#iefix) format('embedded-opentype'),
        url(${GilroyRegularItalicWoff2}) format('woff2'),
        url(${GilroyRegularItalicWoff}) format('woff'),
        url(${GilroyRegularItalicTtf}) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(${GilroySemiboldItalicEot});
    src: local('Gilroy Semibold Italic'), local('Gilroy-SemiboldItalic'),
        url(${GilroySemiboldItalicEot}?#iefix) format('embedded-opentype'),
        url(${GilroySemiboldItalicWoff2}) format('woff2'),
        url(${GilroySemiboldItalicWoff}) format('woff'),
        url(${GilroySemiboldItalicTtf}) format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(${GilroyHeavyItalicEot});
    src: local('Gilroy Heavy Italic'), local('Gilroy-HeavyItalic'),
        url(${GilroyHeavyItalicEot}?#iefix) format('embedded-opentype'),
        url(${GilroyHeavyItalicWoff2}) format('woff2'),
        url(${GilroyHeavyItalicWoff}) format('woff'),
        url(${GilroyHeavyItalicTtf}) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(${GilroyExtraboldEot});
    src: local('Gilroy Extrabold'), local('Gilroy-Extrabold'),
        url(${GilroyExtraboldEot}?#iefix) format('embedded-opentype'),
        url(${GilroyExtraboldWoff2}) format('woff2'),
        url(${GilroyExtraboldWoff}) format('woff'),
        url(${GilroyExtraboldTtf}) format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url(${GilroyBoldItalicEot});
    src: local('Gilroy Bold Italic'), local('Gilroy-BoldItalic'),
        url(${GilroyBoldItalicEot}?#iefix) format('embedded-opentype'),
        url(${GilroyBoldItalicWoff2}) format('woff2'),
        url(${GilroyBoldItalicWoff}) format('woff'),
        url(${GilroyBoldItalicTtf}) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(${GilroyUltraLightItalicEot});
    src: local('Gilroy UltraLight Italic'), local('Gilroy-UltraLightItalic'),
        url(${GilroyUltraLightItalicEot}?#iefix) format('embedded-opentype'),
        url(${GilroyUltraLightItalicWoff2}) format('woff2'),
        url(${GilroyUltraLightItalicWoff}) format('woff'),
        url(${GilroyUltraLightItalicTtf}) format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(${GilroyLightItalicEot});
    src: local('Gilroy Light Italic'), local('Gilroy-LightItalic'),
        url(${GilroyLightItalicEot}?#iefix) format('embedded-opentype'),
        url(${GilroyLightItalicWoff2}) format('woff2'),
        url(${GilroyLightItalicWoff}) format('woff'),
        url(${GilroyLightItalicTtf}) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Gilroy';
    src: url(${GilroyHeavyEot});
    src: local('Gilroy Heavy'), local('Gilroy-Heavy'),
        url(${GilroyHeavyEot}?#iefix) format('embedded-opentype'),
        url(${GilroyHeavyWoff2}) format('woff2'),
        url(${GilroyHeavyWoff}) format('woff'),
        url(${GilroyHeavyTtf}) format('truetype');
    font-weight: 900;
    font-style: normal;
}

a {
    text-decoration: none;
}

nav a li {
    opacity: 0.4;
}

nav a.active li  {
    opacity: 1;
}
`;
