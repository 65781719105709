const Blocks = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9263 0H1.75789C0.788421 0 0 0.788421 0 1.75789V11.9263C0 12.8958 0.788421 13.6842 1.75789 13.6842H11.9263C12.8958 13.6842 13.6842 12.8958 13.6842 11.9263V1.75789C13.6842 0.788421 12.8958 0 11.9263 0Z"
        fill="#2F2F2F"
      />
      <path
        d="M17.547 13.6842H27.7154C28.6849 13.6842 29.4733 12.8958 29.4733 11.9263V1.75789C29.4733 0.788421 28.6849 0 27.7154 0H17.547C16.5775 0 15.7891 0.788421 15.7891 1.75789V11.9263C15.7891 12.8958 16.5775 13.6842 17.547 13.6842Z"
        fill="#2F2F2F"
      />
      <path
        d="M11.9263 15.7895H1.75789C0.788421 15.7895 0 16.5779 0 17.5474V27.7158C0 28.6853 0.788421 29.4737 1.75789 29.4737H11.9263C12.8958 29.4737 13.6842 28.6853 13.6842 27.7158V17.5474C13.6842 16.5779 12.8958 15.7895 11.9263 15.7895Z"
        fill="#2F2F2F"
      />
      <path
        d="M28.9479 21.5789H23.6847V16.3158C23.6847 15.7347 23.2131 15.2632 22.6321 15.2632C22.051 15.2632 21.5795 15.7347 21.5795 16.3158V21.5789H16.3163C15.7353 21.5789 15.2637 22.0505 15.2637 22.6316C15.2637 23.2126 15.7353 23.6842 16.3163 23.6842H21.5795V28.9474C21.5795 29.5284 22.051 30 22.6321 30C23.2131 30 23.6847 29.5284 23.6847 28.9474V23.6842H28.9479C29.5289 23.6842 30.0005 23.2126 30.0005 22.6316C30.0005 22.0505 29.5289 21.5789 28.9479 21.5789Z"
        fill="#2F2F2F"
      />
    </svg>
  );
};

export default Blocks;
