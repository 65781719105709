const MonetizationCircle = () => {
  return (
    <svg
      width="889"
      height="889"
      viewBox="0 0 889 889"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M889 444.5C889 689.991 689.991 889 444.5 889C199.009 889 0 689.991 0 444.5C0 199.009 199.009 0 444.5 0C689.991 0 889 199.009 889 444.5ZM83.8466 444.5C83.8466 643.683 245.317 805.153 444.5 805.153C643.683 805.153 805.153 643.683 805.153 444.5C805.153 245.317 643.683 83.8466 444.5 83.8466C245.317 83.8466 83.8466 245.317 83.8466 444.5Z"
        fill="url(#paint0_linear_389_1700)"
      />
      <path
        d="M889 444.5C889 689.991 689.991 889 444.5 889C199.009 889 0 689.991 0 444.5C0 199.009 199.009 0 444.5 0C689.991 0 889 199.009 889 444.5ZM83.8466 444.5C83.8466 643.683 245.317 805.153 444.5 805.153C643.683 805.153 805.153 643.683 805.153 444.5C805.153 245.317 643.683 83.8466 444.5 83.8466C245.317 83.8466 83.8466 245.317 83.8466 444.5Z"
        fill="url(#paint1_linear_389_1700)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_389_1700"
          x1="444.5"
          y1="0"
          x2="115.57"
          y2="817.88"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FBFF44" />
          <stop offset="1" stopColor="#80FF00" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_389_1700"
          x1="444.5"
          y1="0"
          x2="115.57"
          y2="817.88"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#DADADA" />
          <stop offset="0.155" stopColor="#C4C4C4" />
          <stop offset="0.495" stopColor="#EAEAEA" />
          <stop offset="0.72" stopColor="#A7A7A7" />
          <stop offset="0.87" stopColor="#E7E7E7" />
          <stop offset="1" stopColor="#9C9C9C" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MonetizationCircle;
