const Phone = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="52" height="52" rx="20" fill="#F5F5F5" />
      <g clipPath="url(#clip0_389_589)">
        <path
          d="M36.5262 30.9455C36.4865 30.9125 31.9895 27.7085 30.7752 27.9088C30.1895 28.0122 29.855 28.412 29.1838 29.2115C29.0758 29.3405 28.8155 29.6488 28.6145 29.8685C28.1902 29.7303 27.7764 29.5619 27.3762 29.3645C25.3104 28.3588 23.6412 26.6896 22.6355 24.6237C22.4381 24.2236 22.2697 23.8098 22.1315 23.3855C22.352 23.1838 22.661 22.9235 22.793 22.8125C23.5887 22.145 23.9878 21.8097 24.0912 21.2232C24.3035 20.009 21.0875 15.5135 21.0545 15.473C20.908 15.2653 20.7173 15.0927 20.496 14.9677C20.2748 14.8426 20.0285 14.7683 19.775 14.75C18.4715 14.75 14.75 19.577 14.75 20.3907C14.75 20.438 14.8183 25.241 20.741 31.2658C26.759 37.1817 31.562 37.25 31.6092 37.25C32.4223 37.25 37.25 33.5285 37.25 32.225C37.2315 31.9715 37.157 31.7252 37.0319 31.5039C36.9067 31.2827 36.734 31.092 36.5262 30.9455ZM31.526 35.7455C30.875 35.69 26.84 35.1583 21.8 30.2075C16.8252 25.1428 16.307 21.101 16.2552 20.4748C17.2383 18.9317 18.4256 17.5288 19.7847 16.304C19.8147 16.334 19.8545 16.379 19.9055 16.4375C20.9479 17.8604 21.8458 19.3837 22.586 20.9847C22.3453 21.2269 22.0909 21.455 21.824 21.668C21.4101 21.9833 21.0301 22.3408 20.69 22.7345C20.6325 22.8152 20.5915 22.9066 20.5695 23.0033C20.5475 23.1 20.5448 23.2 20.5617 23.2978C20.7205 23.9853 20.9636 24.6506 21.2855 25.2785C22.4389 27.647 24.3528 29.5607 26.7215 30.7137C27.3493 31.0361 28.0146 31.2795 28.7023 31.4382C28.8 31.4552 28.9 31.4525 28.9967 31.4305C29.0934 31.4085 29.1848 31.3675 29.2655 31.31C29.6606 30.9685 30.0193 30.587 30.3358 30.1715C30.5712 29.891 30.8855 29.5168 31.0047 29.411C32.6099 30.1505 34.1365 31.0495 35.5618 32.0945C35.624 32.147 35.6683 32.1875 35.6975 32.2137C34.4727 33.5733 33.0694 34.7609 31.526 35.744V35.7455Z"
          fill="black"
        />
        <path
          d="M26.75 20.75C27.9431 20.7512 29.087 21.2257 29.9307 22.0693C30.7743 22.913 31.2488 24.0569 31.25 25.25C31.25 25.4489 31.329 25.6397 31.4697 25.7803C31.6103 25.921 31.8011 26 32 26C32.1989 26 32.3897 25.921 32.5303 25.7803C32.671 25.6397 32.75 25.4489 32.75 25.25C32.7482 23.6592 32.1155 22.1342 30.9907 21.0093C29.8658 19.8845 28.3408 19.2518 26.75 19.25C26.5511 19.25 26.3603 19.329 26.2197 19.4697C26.079 19.6103 26 19.8011 26 20C26 20.1989 26.079 20.3897 26.2197 20.5303C26.3603 20.671 26.5511 20.75 26.75 20.75Z"
          fill="black"
        />
        <path
          d="M26.75 17C28.9372 17.0026 31.0342 17.8726 32.5808 19.4192C34.1274 20.9658 34.9974 23.0628 35 25.25C35 25.4489 35.079 25.6397 35.2197 25.7803C35.3603 25.921 35.5511 26 35.75 26C35.9489 26 36.1397 25.921 36.2803 25.7803C36.421 25.6397 36.5 25.4489 36.5 25.25C36.497 22.6651 35.4688 20.1868 33.641 18.359C31.8132 16.5312 29.3349 15.503 26.75 15.5C26.5511 15.5 26.3603 15.579 26.2197 15.7197C26.079 15.8603 26 16.0511 26 16.25C26 16.4489 26.079 16.6397 26.2197 16.7803C26.3603 16.921 26.5511 17 26.75 17Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_389_589">
          <rect width="24" height="24" fill="white" transform="translate(14 14)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Phone;
