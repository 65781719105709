import styled from 'styled-components';

const StyledH4 = styled.h4({
  fontFamily: 'Gilroy',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '19.09px'
});

export default StyledH4;
