import styled from 'styled-components';

const StyledFooter = styled.footer({
  padding: '30px 12px',
  textAlign: 'center',
  fontFamily: 'Gilroy',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '20px',
  letterSpacing: '0.02em',
  marginTop: '40px'
});

const StyledText = styled.span({
  fontFamily: 'Gilroy',
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '20px',
  letterSpacing: '0.02em',
  textAlign: 'center',
  color: '#131313',
  opacity: '40%',
  display: 'block',
  margin: '10px auto 0',
  maxWidth: '780px'
});

const Footer = () => {
  return (
    <StyledFooter>
      <StyledText>
        Photos of celebrities used on this website are for informational or illustrative purposes
        only. We do not claim any copyrights or image rights to these photos. All rights to the
        images belong to their rightful owners.
      </StyledText>
    </StyledFooter>
  );
};

export default Footer;
