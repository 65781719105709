const InvestorSvg1 = () => {
  return (
    <svg
      width="255"
      height="350"
      viewBox="0 0 255 350"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M255 -94C255 151.214 56.2144 350 -189 350C-434.214 350 -633 151.214 -633 -94C-633 -339.214 -434.214 -538 -189 -538C56.2144 -538 255 -339.214 255 -94ZM-549.248 -94C-549.248 104.959 -387.959 266.248 -189 266.248C9.95935 266.248 171.248 104.959 171.248 -94C171.248 -292.959 9.95935 -454.248 -189 -454.248C-387.959 -454.248 -549.248 -292.959 -549.248 -94Z"
        fill="#16451C"
      />
      <path
        d="M255 -94C255 151.214 56.2144 350 -189 350C-434.214 350 -633 151.214 -633 -94C-633 -339.214 -434.214 -538 -189 -538C56.2144 -538 255 -339.214 255 -94ZM-549.248 -94C-549.248 104.959 -387.959 266.248 -189 266.248C9.95935 266.248 171.248 104.959 171.248 -94C171.248 -292.959 9.95935 -454.248 -189 -454.248C-387.959 -454.248 -549.248 -292.959 -549.248 -94Z"
        fill="url(#paint0_linear_389_1699)"
      />
      <path
        d="M255 -94C255 151.214 56.2144 350 -189 350C-434.214 350 -633 151.214 -633 -94C-633 -339.214 -434.214 -538 -189 -538C56.2144 -538 255 -339.214 255 -94ZM-549.248 -94C-549.248 104.959 -387.959 266.248 -189 266.248C9.95935 266.248 171.248 104.959 171.248 -94C171.248 -292.959 9.95935 -454.248 -189 -454.248C-387.959 -454.248 -549.248 -292.959 -549.248 -94Z"
        fill="url(#paint1_linear_389_1699)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_389_1699"
          x1="-189"
          y1="-538"
          x2="-517.56"
          y2="278.96"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.155" stopColor="#1F5926" />
          <stop offset="0.265" stopColor="#61C200" />
          <stop offset="0.375" stopColor="#1F5926" />
          <stop offset="0.495" stopColor="#61C200" />
          <stop offset="0.635" stopColor="#1F5926" />
          <stop offset="0.745" stopColor="#61C200" />
          <stop offset="0.87" stopColor="#1F5926" />
          <stop offset="1" stopColor="#61C200" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_389_1699"
          x1="-189"
          y1="-538"
          x2="-517.56"
          y2="278.96"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#DADADA" />
          <stop offset="0.155" stopColor="#C4C4C4" />
          <stop offset="0.495" stopColor="#EAEAEA" />
          <stop offset="0.72" stopColor="#A7A7A7" />
          <stop offset="0.87" stopColor="#E7E7E7" />
          <stop offset="1" stopColor="#9C9C9C" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default InvestorSvg1;
