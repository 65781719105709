import styled from 'styled-components';

const StyledH2 = styled.h2({
  fontFamily: 'Gilroy',
  fontSize: '70px',
  fontWeight: 600,
  lineHeight: '83.51px',
  textAlign: 'center',
  '@media (max-width: 600px)': {
    fontSize: '38px',
    lineHeight: '45.33px'
  }
});

export default StyledH2;
