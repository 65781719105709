import styled from 'styled-components';
import StyledH2 from '../UI/StyledH2';
import userPhone from '../../assets/images/userPhone.webp';
import mobileUserPhone from '../../assets/images/mobileUserPhone.webp';
import CircleSvg from '../svg/Circle';
import Circle2 from '../svg/Circle2';
import GreenCircle from '../svg/GreenCircle';

const StyledLayout = styled.section({
  height: '90vh',
  overflow: 'hidden',
  position: 'relative'
});

const StyledImg = styled.img({
  width: '100%',
  height: '100%',
  minHeight: '60vh',
  objectFit: 'contain',
  position: 'absolute',
  bottom: '-215px',
  '@media (max-width: 600px)': {
    objectFit: 'cover'
  }
});

const StyledCircle1 = styled.div({
  position: 'absolute',
  maxWidth: '20vw',
  zIndex: -1,
  '> svg': {
    width: '100%'
  }
});

const StyledCircle2 = styled.div({
  position: 'absolute',
  right: 0,
  maxWidth: '10vw',
  zIndex: -1,
  '> svg': {
    width: '100%'
  }
});

const StyledCircle3 = styled.div({
  position: 'absolute',
  bottom: '-50%',
  left: '50%',
  transform: 'translate(-50%)',
  zIndex: -1,
  maxWidth: '50vw',
  maxHeight: '90vh',
  '> svg': {
    width: '100%'
  }
});

const Embrace = () => {
  const isMobile = window.innerWidth <= 600;

  return (
    <StyledLayout>
      <StyledCircle1>
        <CircleSvg />
      </StyledCircle1>
      <StyledCircle2>
        <Circle2 />
      </StyledCircle2>
      <StyledCircle3>
        <GreenCircle />
      </StyledCircle3>
      <div style={{ marginTop: '70px' }}>
        <StyledH2>Embrace Change,</StyledH2>
        <StyledH2>Embrace Better Habits.</StyledH2>
      </div>
      <StyledImg src={isMobile ? mobileUserPhone : userPhone} alt="Phone photo" />
    </StyledLayout>
  );
};

export default Embrace;
