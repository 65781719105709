const Monetezation2 = () => {
  return (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="60" height="60" rx="20" fill="#131313" />
      <g clipPath="url(#clip0_476_1226)">
        <path
          d="M28.3636 35.4545C28.3636 36.0545 27.8727 36.5455 27.2727 36.5455C26.6727 36.5455 26.1818 36.0545 26.1818 35.4545C26.1818 34.8545 26.6727 34.3636 27.2727 34.3636C27.8727 34.3636 28.3636 34.8545 28.3636 35.4545ZM32.7273 28.9091C32.1273 28.9091 31.6364 29.4 31.6364 30C31.6364 30.6 32.1273 31.0909 32.7273 31.0909C33.3273 31.0909 33.8182 30.6 33.8182 30C33.8182 29.4 33.3273 28.9091 32.7273 28.9091ZM45 30C45 38.2909 38.2909 45 30 45C21.7091 45 15 38.2909 15 30C15 21.7091 21.7091 15 30 15C38.2909 15 45 21.7091 45 30ZM38.1818 34.6364H29.8636C29.5091 33.5182 28.4727 32.7273 27.2727 32.7273C26.0727 32.7273 25.0091 33.5182 24.6818 34.6364H21.8182V36.2727H24.6818C25.0364 37.3909 26.0727 38.1818 27.2727 38.1818C28.4727 38.1818 29.5364 37.3909 29.8636 36.2727H38.1818V34.6364ZM38.1818 29.1818H35.3182C34.9636 28.0636 33.9273 27.2727 32.7273 27.2727C31.5273 27.2727 30.4636 28.0636 30.1364 29.1818H21.8182V30.8182H30.1364C30.4909 31.9364 31.5273 32.7273 32.7273 32.7273C33.9273 32.7273 34.9909 31.9364 35.3182 30.8182H38.1818V29.1818ZM38.1818 23.7273H29.8636C29.5091 22.6091 28.4727 21.8182 27.2727 21.8182C26.0727 21.8182 25.0091 22.6091 24.6818 23.7273H21.8182V25.3636H24.6818C25.0364 26.4818 26.0727 27.2727 27.2727 27.2727C28.4727 27.2727 29.5364 26.4818 29.8636 25.3636H38.1818V23.7273ZM27.2727 23.4545C26.6727 23.4545 26.1818 23.9455 26.1818 24.5455C26.1818 25.1455 26.6727 25.6364 27.2727 25.6364C27.8727 25.6364 28.3636 25.1455 28.3636 24.5455C28.3636 23.9455 27.8727 23.4545 27.2727 23.4545Z"
          fill="black"
        />
        <path
          d="M28.3636 35.4545C28.3636 36.0545 27.8727 36.5455 27.2727 36.5455C26.6727 36.5455 26.1818 36.0545 26.1818 35.4545C26.1818 34.8545 26.6727 34.3636 27.2727 34.3636C27.8727 34.3636 28.3636 34.8545 28.3636 35.4545ZM32.7273 28.9091C32.1273 28.9091 31.6364 29.4 31.6364 30C31.6364 30.6 32.1273 31.0909 32.7273 31.0909C33.3273 31.0909 33.8182 30.6 33.8182 30C33.8182 29.4 33.3273 28.9091 32.7273 28.9091ZM45 30C45 38.2909 38.2909 45 30 45C21.7091 45 15 38.2909 15 30C15 21.7091 21.7091 15 30 15C38.2909 15 45 21.7091 45 30ZM38.1818 34.6364H29.8636C29.5091 33.5182 28.4727 32.7273 27.2727 32.7273C26.0727 32.7273 25.0091 33.5182 24.6818 34.6364H21.8182V36.2727H24.6818C25.0364 37.3909 26.0727 38.1818 27.2727 38.1818C28.4727 38.1818 29.5364 37.3909 29.8636 36.2727H38.1818V34.6364ZM38.1818 29.1818H35.3182C34.9636 28.0636 33.9273 27.2727 32.7273 27.2727C31.5273 27.2727 30.4636 28.0636 30.1364 29.1818H21.8182V30.8182H30.1364C30.4909 31.9364 31.5273 32.7273 32.7273 32.7273C33.9273 32.7273 34.9909 31.9364 35.3182 30.8182H38.1818V29.1818ZM38.1818 23.7273H29.8636C29.5091 22.6091 28.4727 21.8182 27.2727 21.8182C26.0727 21.8182 25.0091 22.6091 24.6818 23.7273H21.8182V25.3636H24.6818C25.0364 26.4818 26.0727 27.2727 27.2727 27.2727C28.4727 27.2727 29.5364 26.4818 29.8636 25.3636H38.1818V23.7273ZM27.2727 23.4545C26.6727 23.4545 26.1818 23.9455 26.1818 24.5455C26.1818 25.1455 26.6727 25.6364 27.2727 25.6364C27.8727 25.6364 28.3636 25.1455 28.3636 24.5455C28.3636 23.9455 27.8727 23.4545 27.2727 23.4545Z"
          fill="url(#paint0_linear_476_1226)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_476_1226"
          x1="30"
          y1="15"
          x2="18.9"
          y2="42.6"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#DADADA" />
          <stop offset="0.155" stopColor="#C4C4C4" />
          <stop offset="0.495" stopColor="#EAEAEA" />
          <stop offset="0.72" stopColor="#A7A7A7" />
          <stop offset="0.87" stopColor="#E7E7E7" />
          <stop offset="1" stopColor="#9C9C9C" />
        </linearGradient>
        <clipPath id="clip0_476_1226">
          <rect width="30" height="30" fill="white" transform="translate(15 15)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Monetezation2;
