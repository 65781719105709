import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

const StyledList = styled.ul({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: '12px',
  '@media (max-width: 600px)': {
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-around'
  }
});

const StyledListItem = styled.li({
  fontFamily: 'Gilroy',
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '21.47px',
  textAlign: 'left',
  marginLeft: '30px',
  color: '#2F2F2F',
  '> a': {
    display: 'flex',
    alignItems: 'center'
  },
  '@media (max-width: 600px)': {
    marginLeft: '0'
  }
});

const BurgerContainer = styled.div({
  minWidth: '50px',
  minHeight: '50px',
  borderRadius: '50%',
  background: '#F6F6F6',
  display: 'flex',
  alignItems: 'center',
  zIndex: 9,
  justifyContent: 'center'
});

const Burger = styled.div({
  borderTop: '2px solid #2F2F2F',
  borderBottom: '2px solid #2F2F2F',
  width: '20px',
  height: '10px',
  position: 'relative',
  '&::after': {
    content: "''",
    position: 'absolute',
    top: 'calc(50% - 1px)',
    left: 0,
    width: '20px',
    height: '2px',
    background: '#2F2F2F'
  }
});

const Close = styled.div({
  minWidth: '50px',
  minHeight: '50px',
  position: 'relative',
  zIndex: 9,
  '&::after': {
    content: "''",
    width: '20px',
    height: '2px',
    background: '#2F2F2F',
    position: 'absolute',
    top: '50%',
    left: 'calc(50% - 10px)',
    transform: 'rotate(-45deg)'
  },
  '&::before': {
    content: "''",
    width: '20px',
    height: '2px',
    background: '#2F2F2F',
    position: 'absolute',
    top: '50%',
    left: 'calc(50% - 10px)',
    transform: 'rotate(45deg)'
  }
});

const ContactsListItem = styled(StyledListItem)({
  borderBottom: '1px solid #2F2F2F'
});

const StyledNav = styled.nav({
  '@media (max-width: 600px)': {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100%',
    background: 'white'
  }
});

const Navigation = () => {
  const [show, setShow] = useState(false);
  const location = useLocation();

  const width = window.innerWidth;
  const isMobile = width <= 600;

  const openMenu = () => {
    setShow(true);
  };

  const closeMenu = () => {
    setShow(false);
  };

  useEffect(() => {
    setShow(false);
  }, [location]);

  useEffect(() => {
    if (show) {
      document.body.style = 'overflow: hidden;';
      return;
    }

    document.body.style = '';
  }, [show]);

  const navStyles = isMobile && !show ? { display: 'none', overflow: 'hidden' } : {};

  const MobileMenu = () => {
    if (!isMobile) {
      return null;
    }

    if (show) {
      return <Close onClick={closeMenu} />;
    }

    return (
      <BurgerContainer onClick={openMenu}>
        <Burger />
      </BurgerContainer>
    );
  };

  return (
    <>
      <MobileMenu />
      <StyledNav style={navStyles}>
        <StyledList>
          <NavLink to="/">
            <StyledListItem>For User</StyledListItem>
          </NavLink>
          <NavLink to="/influencer">
            <StyledListItem>For Influencer</StyledListItem>
          </NavLink>
          <NavLink to="/investor">
            <StyledListItem>For Investor</StyledListItem>
          </NavLink>
          <NavHashLink smooth to="#contacts">
            <ContactsListItem>Contacts</ContactsListItem>
          </NavHashLink>
        </StyledList>
      </StyledNav>
    </>
  );
};

export default Navigation;
