import styled from 'styled-components';

const StyledH3 = styled.h3({
  fontFamily: 'Gilroy',
  fontSize: '30px',
  fontStyle: 'italic',
  fontWeight: 600,
  lineHeight: '36.27px',
  color: '#2F2F2F'
});

export default StyledH3;
