const CircleSvg = () => {
  return (
    <svg
      width="322"
      height="382"
      viewBox="0 0 322 382"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M322 -62C322 183.214 123.214 382 -122 382C-367.214 382 -566 183.214 -566 -62C-566 -307.214 -367.214 -506 -122 -506C123.214 -506 322 -307.214 322 -62ZM-482.248 -62C-482.248 136.959 -320.959 298.248 -122 298.248C76.9594 298.248 238.248 136.959 238.248 -62C238.248 -260.959 76.9594 -422.248 -122 -422.248C-320.959 -422.248 -482.248 -260.959 -482.248 -62Z"
        fill="#F5F5F5"
      />
    </svg>
  );
};

export default CircleSvg;
