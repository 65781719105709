const LeftStars = () => {
  return (
    <svg
      width="136"
      height="136"
      viewBox="0 0 136 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_352_657)">
        <path
          d="M67.629 41.4593C54.618 43.2128 41.7966 37.1015 34.9655 25.8898C34.1268 24.5135 32.438 23.9132 30.9184 24.4518C29.3989 24.9903 28.465 26.5201 28.6804 28.1175C30.4341 41.1286 24.3227 53.9497 13.1112 60.7807C11.7347 61.6194 11.1343 63.3084 11.6728 64.8278C12.2113 66.3471 13.7411 67.2812 15.3387 67.0657C28.3496 65.3123 41.1705 71.4235 48.0017 82.635C48.8403 84.0115 50.5292 84.612 52.0488 84.0734C53.5681 83.5349 54.5021 82.0051 54.2867 80.4075C52.5332 67.3964 58.6448 54.5751 69.8564 47.7444C71.2329 46.9057 71.8334 45.2168 71.2948 43.6973C70.7561 42.178 69.2263 41.244 67.629 41.4593Z"
          fill="#D6DFFF"
        />
        <path
          d="M81.1214 96.3826C74.9913 97.209 68.9505 94.3297 65.7316 89.0471C64.893 87.6706 63.2041 87.0701 61.6845 87.6086C60.1652 88.1471 59.2311 89.6768 59.4465 91.2744C60.2726 97.4048 57.393 103.446 52.1108 106.664C50.7343 107.503 50.1338 109.192 50.6723 110.711C51.2108 112.231 52.7407 113.165 54.3383 112.949C60.4683 112.123 66.5093 115.003 69.728 120.285C70.5666 121.662 72.2555 122.262 73.775 121.724C75.2944 121.185 76.2285 119.655 76.013 118.058C75.1869 111.927 78.0665 105.886 83.3489 102.668C84.7254 101.829 85.3259 100.14 84.7874 98.6206C84.2489 97.1012 82.7191 96.1674 81.1214 96.3826Z"
          fill="#D6DFFF"
        />
        <path
          d="M113.964 51.2789C105.678 52.3956 97.5129 48.5036 93.1626 41.3637C92.3239 39.9872 90.6351 39.3867 89.1155 39.9253C87.5962 40.4638 86.6621 41.9935 86.8775 43.5911C87.9942 51.8772 84.1024 60.0421 76.9624 64.3925C75.5859 65.2311 74.9855 66.9202 75.5239 68.4395C76.0624 69.9589 77.5922 70.8927 79.1899 70.6775C87.4758 69.5608 95.6408 73.4526 99.9911 80.5927C100.83 81.9692 102.519 82.5697 104.038 82.0311C105.557 81.4926 106.492 79.9629 106.276 78.3651C105.16 70.079 109.051 61.914 116.191 57.5639C117.568 56.7251 118.168 55.0362 117.63 53.5169C117.091 51.9976 115.561 51.0635 113.964 51.2789Z"
          fill="#D6DFFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_352_657">
          <rect
            width="106"
            height="106"
            fill="white"
            transform="translate(0 35.4101) rotate(-19.5152)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LeftStars;
