import styled from 'styled-components';

const StyledContent = styled.section({
  maxWidth: '1440px',
  display: 'flex',
  alignItems: 'center',
  margin: '0 auto',
  '@media (max-width: 600px)': {
    maxWidth: 'initial'
  }
});

export default StyledContent;
