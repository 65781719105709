const InvestorSvg2 = () => {
  return (
    <svg
      width="360"
      height="249"
      viewBox="0 0 360 249"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M888 -196.5C888 49.5428 689.214 249 444 249C198.786 249 0 49.5428 0 -196.5C0 -442.543 198.786 -642 444 -642C689.214 -642 888 -442.543 888 -196.5ZM83.7523 -196.5C83.7523 3.13147 245.041 164.965 444 164.965C642.959 164.965 804.248 3.13147 804.248 -196.5C804.248 -396.131 642.959 -557.965 444 -557.965C245.041 -557.965 83.7523 -396.131 83.7523 -196.5Z"
        fill="#16451C"
      />
      <path
        d="M888 -196.5C888 49.5428 689.214 249 444 249C198.786 249 0 49.5428 0 -196.5C0 -442.543 198.786 -642 444 -642C689.214 -642 888 -442.543 888 -196.5ZM83.7523 -196.5C83.7523 3.13147 245.041 164.965 444 164.965C642.959 164.965 804.248 3.13147 804.248 -196.5C804.248 -396.131 642.959 -557.965 444 -557.965C245.041 -557.965 83.7523 -396.131 83.7523 -196.5Z"
        fill="url(#paint0_linear_389_1701)"
      />
      <path
        d="M888 -196.5C888 49.5428 689.214 249 444 249C198.786 249 0 49.5428 0 -196.5C0 -442.543 198.786 -642 444 -642C689.214 -642 888 -442.543 888 -196.5ZM83.7523 -196.5C83.7523 3.13147 245.041 164.965 444 164.965C642.959 164.965 804.248 3.13147 804.248 -196.5C804.248 -396.131 642.959 -557.965 444 -557.965C245.041 -557.965 83.7523 -396.131 83.7523 -196.5Z"
        fill="url(#paint1_linear_389_1701)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_389_1701"
          x1="195.5"
          y1="-615"
          x2="581.605"
          y2="196.402"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.155" stopColor="#1F5926" />
          <stop offset="0.265" stopColor="#61C200" />
          <stop offset="0.375" stopColor="#1F5926" />
          <stop offset="0.495" stopColor="#61C200" />
          <stop offset="0.635" stopColor="#1F5926" />
          <stop offset="0.745" stopColor="#61C200" />
          <stop offset="0.87" stopColor="#1F5926" />
          <stop offset="1" stopColor="#61C200" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_389_1701"
          x1="444"
          y1="-642"
          x2="113.528"
          y2="176.948"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#DADADA" />
          <stop offset="0.155" stopColor="#C4C4C4" />
          <stop offset="0.495" stopColor="#EAEAEA" />
          <stop offset="0.72" stopColor="#A7A7A7" />
          <stop offset="0.87" stopColor="#E7E7E7" />
          <stop offset="1" stopColor="#9C9C9C" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default InvestorSvg2;
