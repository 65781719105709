/* eslint-disable react/no-unescaped-entities, no-irregular-whitespace */
import styled from 'styled-components';
import about from '../../assets/images/About.webp';
import Logo from '../UI/Logo';
import StyledContent from '../UI/StyledContent';
import StyledParagraph from '../UI/StyledParagraph';

const StyledWrapper = styled.section({
  background: '#081A0A',
  padding: '98px 80px',
  '@media (max-width: 600px)': {
    padding: '30px'
  }
});

const StyledContentAbout = styled(StyledContent)({
  '@media (max-width: 1255px)': {
    flexWrap: 'wrap'
  }
});

const StyledImg = styled.img({
  height: '483.68px',
  maxWidth: '100%',
  objectFit: 'contain'
});

const StyledTitle = styled.h2({
  fontFamily: 'Gilroy',
  fontSize: '50px',
  fontStyle: 'italic',
  fontWeight: 600,
  lineHeight: '59.65px',
  textAlign: 'left',
  color: 'white',
  '@media (max-width: 600px)': {
    fontSize: '40px'
  }
});

const StyledDiv = styled.div({
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 600px)': {
    width: '100%',
    justifyContent: 'center'
  }
});

const StyledDivInfo = styled(StyledDiv)({
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginLeft: '80px',
  '> p': {
    margin: '5px 0'
  },
  '@media (max-width: 600px)': {
    marginLeft: 0,
    width: '100%',
    textAlign: 'center'
  }
});

const About = () => {
  return (
    <StyledWrapper>
      <StyledContentAbout>
        <StyledImg src={about} />
        <StyledDivInfo>
          <StyledDiv style={{ marginBottom: '20px' }}>
            <Logo />
            <StyledTitle style={{ marginLeft: '23px' }}>About us</StyledTitle>
          </StyledDiv>
          <StyledParagraph>
            Habits play a key role in a human's daily life, determining their performance, emotional
            state and overall well-being. Research in psychology and behavioral science shows that
            up to 40% of our actions throughout the day are the result of habits, highlighting their
            importance in shaping our behavior and performance. But what if you want to self-improve
            and follow the beaten path of someone who is an expert at it? Be closer to your idol or
            focus on improving your lifestyle by implementing healthy habits? This is exactly what
            Habs is all about.
          </StyledParagraph>
          <StyledParagraph>
            Our product will allow Influencers to share their habits and daily lifestyle with their
            subscribers.  From a subscriber's point of view, this is an opportunity to be closer to
            their idol or follow the habits of a titled athlete or beauty blogger, each of whom
            knows a lot about their niche and how to stay on track. Habs will help many people
            become healthier and implement healthy habits to increase their tone, productivity and
            overall happiness levels 
          </StyledParagraph>
        </StyledDivInfo>
      </StyledContentAbout>
    </StyledWrapper>
  );
};

export default About;
