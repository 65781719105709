import styled from 'styled-components';

const StyledButton = styled.div({
  background: '#2F2F2F',
  padding: '10px 0px',
  borderRadius: '10px',
  color: 'white',
  fontFamily: 'Gilroy',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '19.09px',
  textAlign: 'center',
  textTransform: 'uppercase'
});

export default StyledButton;
