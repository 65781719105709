import styled from 'styled-components';
import logo from '../../assets/images/logo.webp';

const StyledImg = styled.img({
  width: '50px',
  height: '50px'
});

const Logo = () => <StyledImg src={logo} alt="Habs" />;

export default Logo;
