const Influencer2 = () => {
  return (
    <svg
      width="219"
      height="807"
      viewBox="0 0 219 807"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M888 361.5C888 607.543 689.214 807 444 807C198.786 807 0 607.543 0 361.5C0 115.457 198.786 -84 444 -84C689.214 -84 888 115.457 888 361.5ZM83.7523 361.5C83.7523 561.131 245.041 722.965 444 722.965C642.959 722.965 804.248 561.131 804.248 361.5C804.248 161.869 642.959 0.0352173 444 0.0352173C245.041 0.0352173 83.7523 161.869 83.7523 361.5Z"
        fill="#F5F5F5"
      />
    </svg>
  );
};

export default Influencer2;
