import styled from 'styled-components';
import Roadmap from '../Roadmap';
import FirstBlock from './FirstBlock';
import MonetizationOptions from './MonetizationOptions';

const RoadmapWrapper = styled.div({
  paddingTop: '76px',
  borderTop: '2px solid #F5F5F5'
});

const InvestorPage = () => {
  return (
    <>
      <FirstBlock />
      <MonetizationOptions />
      <RoadmapWrapper>
        <Roadmap />
      </RoadmapWrapper>
    </>
  );
};

export default InvestorPage;
