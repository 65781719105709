import styled from 'styled-components';
import Navigation from './Navigation';
import Logo from '../UI/Logo';
import { NavLink } from 'react-router-dom';

const StyledDiv = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: '#2F2F2F'
});

const StyledHeader = styled.header({
  padding: '20px 80px',
  borderBottom: (props) => `1px solid ${props.theme.mainGrey}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  position: 'sticky',
  top: 0,
  background: 'white',
  zIndex: 999,
  '@media (max-width: 600px)': {
    padding: '10px 12px'
  }
});

const StyledTitle = styled.h1({
  fontFamily: 'Gilroy',
  fontSize: '30px',
  fontStyle: 'italic',
  fontWeight: 800,
  lineHeight: '36.27px',
  textAlign: 'center',
  marginLeft: '10px'
});

const Header = () => {
  return (
    <StyledHeader>
      <NavLink to="/">
        <StyledDiv>
          <Logo />
          <StyledTitle>Habs</StyledTitle>
        </StyledDiv>
      </NavLink>
      <Navigation />
    </StyledHeader>
  );
};

export default Header;
